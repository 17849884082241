import React, { useEffect, useState } from "react";
import "./master-helper/investment_aggrement.css";
import digiSign from "../../../images/sign.png";
import service from "../../../service";
import { dateFormatter } from "../../../utils/common";
import toastr from "toastr";
export const InvestmentAggrement = () => {
  const [aggrementData, setAggrementData] = useState({});

  useEffect(() => {
    const status = getAgreementData();
    if (status) {
      const timer = setTimeout(() => {
        window.print();
      }, 1500);

      // Cleanup the timeout if the component unmounts before the timeout completes
      return () => clearTimeout(timer);
    }
  }, []);
  const getAgreementData = async () => {
    const res = await service.requestApi.getAgrement();
    if ("address" in res && "pan" in res && "joinAmount" in res) {
      setAggrementData(res);
      return true;
    } else {
      toastr.error("No data found or Error Occuried", "Error");
      return false;
    }
  };

  return (
    <div>
      <form
        method="post"
        action="./Aggrement.aspx"
        id="form1"
        className="aggrement-form"
      >
        <div className="aspNetHidden">
          <input
            type="hidden"
            name="__VIEWSTATE"
            id="__VIEWSTATE"
            value="/wEPDwUKMTE4NDIxNzk4MWRktO1WpkMGSUHqy4BR803bqIYM+GzMxGaVt1eQfcIVrq4="
          />
        </div>

        <div className="aspNetHidden">
          <input
            type="hidden"
            name="__VIEWSTATEGENERATOR"
            id="__VIEWSTATEGENERATOR"
            value="D3960C9D"
          />
        </div>
        <center style={{ color: "#000000" }}>
          <h1 className="heading1">INVESTMENT AGREEMENT</h1>
        </center>

        <div className="company-details">
          <p style={{ fontWeight: "bold" }}>GMFX GLOBAL LTD. </p>
          <span>
            Sponsor ID- <span id="sponsor">{aggrementData?.reffid ?? ""}</span>{" "}
            <br />
            Investment Date - <span id="lbdor">{aggrementData?.doa ?? ""}</span>
          </span>

          <p>
            <b>Address:</b> Satyalok colony phase 6 near <br /> ranveer garden
            Haldwani <br />
            Nainital pin code:263139{" "}
          </p>
        </div>
        <hr />

        <div className="para">
          <p style={{ fontWeight: "bold" }}>MEMBER DETAILS </p>

          <p>
            Member Name: <span id="lbname">{aggrementData?.name ?? ""}</span>
          </p>
          <p>
            Member Address:{" "}
            <span id="lbaddress">{aggrementData?.address ?? ""}</span>
          </p>
          <p>
            Member Contact No. :{" "}
            <span id="lbcontact">{aggrementData?.mobile ?? ""}</span>
          </p>
        </div>

        <table className="table-aggrement">
          <thead>
            <tr>
              <th>Description</th>

              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Investment amount</td>

              <td>
                Rs, {aggrementData?.joinAmount ?? 0}
                <span id="lbamount"></span>
              </td>
            </tr>
            <tr>
              <td>
                {" "}
                Payout Example : 8% ( 4%- principal amount &amp; 4%- profit )
              </td>

              <td>7-10%</td>
            </tr>
            <tr>
              <td> Total Duration (in months)</td>

              <td> 25-29 months</td>
            </tr>
          </tbody>
        </table>
        <h2>
          <center style={{ color: "#000000" }}>Note:</center>
        </h2>

        <p></p>
        <center style={{ color: "#000000" }}>
          {" "}
          • At the end Investor will get total 200% return of his invested
          amount after the period as satisfied.
        </center>
        <p></p>
        <h1 className="heading1">
          <center>Terms &amp; Conditions</center>
        </h1>

        <p>
          This agreement is made and entered into as of Date :{" "}
          {dateFormatter(aggrementData?.dateofjoin ?? "")}
          <span id="lbdate"></span> by and between PARTY A ("GMFX GLOBAL LTD")
          and PARTY B ("<span id="lbname1">{aggrementData?.name ?? "er"}</span>
          "). Whereas, Investor desires to invest Rs,{" "}
          <span id="lbamt2">{aggrementData?.joinAmount ?? 0}</span> in the
          Company.
        </p>

        <p>
          Whereas, the Company desires to accept the investment from Investor
          subject to the terms and conditions of this Agreement. NOW, THEREFORE,
          in consideration of the mutual promises and covenants contained
          herein, the parties agree as follows:{" "}
        </p>

        <p>
          1. This investment agreement is executed today date :{" "}
          <b style={{ color: "red" }}>
            <span id="date">{aggrementData?.doa ?? ""}</span>
          </b>{" "}
          on the basis of the following conditions and rules between the company
          and the investigation. President Bimal Rawat in behalf of First Party
          Investment Company, Office GMFX Global Limited Company Satyalok Colony
          Face 6 near Ranveer Garden Haldwani District Nainital.
        </p>
        <p>
          2. Second party investor/member Mr./Mrs./Miss :{" "}
          <b style={{ color: "red" }}>
            <span id="name">{aggrementData?.name ?? ""}</span>
          </b>{" "}
          Son Mr. :{" "}
          <b style={{ color: "red" }}>
            <span id="fname">{aggrementData?.fname ?? ""}</span>
          </b>{" "}
          Resident :{" "}
          <b style={{ color: "red" }}>
            <span id="address">{aggrementData?.address ?? ""}</span>
          </b>{" "}
          District :{" "}
          <b style={{ color: "red" }}>
            <span id="district">{aggrementData?.city ?? ""}</span>
          </b>{" "}
          Aadhar card number :{" "}
          <b style={{ color: "red" }}>
            <span id="addharno">{aggrementData?.aadhar ?? ""}</span>
          </b>{" "}
          pan card number :{" "}
          <b style={{ color: "red" }}>
            <span id="panno">{aggrementData?.pan ?? ""}</span>
          </b>{" "}
          Mobile number :{" "}
          <b style={{ color: "red" }}>
            <span id="mobile">{aggrementData?.mobile ?? ""}</span>
          </b>
        </p>
        <p>
          3. Investor / member investment funds will be stored by the bank
          through bank transfer or Google Pay.
        </p>
        <p>
          4. Investor / member will deposit its shared card in the investment
          company per share of the shade of its pan card and the bank account
          number IFSC code.
        </p>
        <p>
          5. Investor/member will be given 4% original deposit amount 4% of the
          money in the investment funds 4%, as far as 25% 29 months. This funds
          will be given in your account after cutting 1% and 2% TDS if you do
          not have a pan number, TDS will be doubled.
        </p>
        <p>
          6. Investment Amount. Investor agrees to invest Rs,$
          {aggrementData?.joinAmount ?? ""}
          <span id="lbamt1"></span> in the Company.{" "}
        </p>
        <p>
          7.The ROI shall be subjected to Force Majeure which prescribes removal
          of liability for unforeseeable and unavoidable catastrophes that
          interrupt the expected course of events and prevent participants from
          fulfilling obligations. These clauses generally cover natural
          disasters, such as hurricanes, tornadoes, and earthquakes, as well as
          human actions, such as armed conflict and man-made diseases.{" "}
        </p>
        <p>
          8. In the event of death of the Investor, his Nominee shall be
          entitled for running the account with same terms and conditions as if
          the investor is running the account.{" "}
        </p>
        <p>
          9. TDS shall be deducted at the rate of 1%/2% as the case may be on
          the ROI.{" "}
        </p>
        <p>
          10. In case PAN number is not furnished TDS shall be deducted @ double
          the rate of applicable rate.{" "}
        </p>
        <p>
          11. Governing Law. This Agreement shall be governed by and construed
          in accordance with the laws of the state of Uttarakhand.{" "}
        </p>

        <p>
          12. Entire Agreement. This Agreement constitutes the entire agreement
          between the parties and supersedes all prior or contemporaneous
          agreements, understandings, and communications, whether written or
          oral, relating to the subject matter hereof..
        </p>
        <p>13. Disclaimer and Limitation of Liability:</p>

        <p>
          <b></b>
        </p>
        <center>
          <b className="aggrement-form-b">
            "The Company guarantees the principal investment amount mentioned
            above. In the event of default or non-performance by the Company,
            the Client shall be entitled to receive the guaranteed principal
            amount"
          </b>
        </center>
        <p></p>

        <p>
          14. The Company does not guarantee the performance of the investment
          or the interest rate offered, and the Client acknowledges that market
          conditions may lead to fluctuations in returns.
        </p>
        <p>
          15. The Client acknowledges that the investment profit is subject to
          market risk.
        </p>
        <p>
          16. The entire agreement This agreement takes place in the next
          agreement or the competition to understanding the agreement between
          the parties and the subject of the subject or contemporary agreements,
          which is written or verbal, it will be given.
        </p>
        <p>
          17. The ROI will be subject to the unexpected event that the provision
          of removing the responsibility for unexpected and inevitable disasters
          which prevents the curriculum of incidents and fulfilling the
          participants from folding the objectives. These segments usually cover
          natural disasters, such as storm, Luma, which is done in the entire
          hosts and the earthquake, as well as human actions, such as joint
          conflict and man-made disk. This investment agreement is to date the
          date of the strikes, man, which, and the emolicer, as well as the
          joint conflict and man-made disk.
        </p>
        <p>
          18. This investment agreement is to the date :{" "}
          <b style={{ color: "red" }}>
            <span id="date1">{aggrementData?.doa ?? "er"}</span>
          </b>{" "}
          The information is done in the entire sentiment and the time of the
          company and investment.
        </p>

        <br />
        <h1 className="heading1">
          <center>INVESTMENT AGREEMENT</center>
        </h1>
        <p>
          {" "}
          IN WITNESS WHEREOF, the parties hereto have executed this Agreement as
          of the date first above written.
        </p>

        <hr />
        <div className="company-details">
          <p style={{ fontWeight: "bold", marginBottom: "1px" }}>
            GMFX DIRECTOR{" "}
          </p>

          <p style={{ fontWeight: "bold" }}>Invester. </p>

          <p>
            By: GMFX GLOBAL LIMITED{" "}
            <img
              src={digiSign}
              width="100px"
              style={{ marginTop: "-50px", marginLeft: "80px" }}
            />
          </p>
          <p>
            By: <span id="lbinvester">{aggrementData?.name ?? ""}</span>{" "}
          </p>
        </div>
        <hr />
      </form>
    </div>
  );
};
