// Example reducer
const initialState = {
  path: 0,
};

export const pathReducer = (state = initialState, action) => {
  switch (action.type) {
    case "path_location":
      return { ...state, storePath: action.path };
    default:
      return state;
  }
};
