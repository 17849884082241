import React, { useState } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Input } from "../../small-components/inputFiled";
import service from "../../../service";
import toastr from "toastr";
import { Link, useNavigate } from "react-router-dom";
import { LoaderSpinner, testPassword } from "../../../utils/common";
import { cancelBtn } from "./master-helper/helper";
export const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const onSubmit = async () => {
    if (oldPassword && newPassword) {
      if (newPassword === confirmPassword) {
        const passValidation = testPassword(newPassword);
        if (passValidation) {
          setLoader(true);
          const res = await service.requestApi.changePassword(
            oldPassword,
            newPassword
          );
          if (res === 1) {
            toastr.success("Password Change Successfully", "Success");
            navigate("/login");
            localStorage.clear();
          } else {
            toastr.error("Invalid Credential", "Error");
          }
          setLoader(false);
        } else {
          toastr.error(
            "Password should have special character, UpperCase and 10 digit in length",
            "Error"
          );
        }
      } else {
        toastr.error(
          "new password and confirm password doesn't match",
          "Error"
        );
      }
    } else {
      toastr.error("Please enter old and new password", "Error");
    }
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Master"} child={"Change Password"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Change Password</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div
                  className="content clearfix"
                  style={{ minHeight: "100px" }}
                >
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"New Password"}
                          name={"new_pass"}
                          required={true}
                          type={"text"}
                          onHandleChange={(e) =>
                            setNewPassword(e?.target?.value ?? "")
                          }
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Confirm Password"}
                          name={"conf_pass"}
                          required={true}
                          type={"text"}
                          onHandleChange={(e) =>
                            setConfirmPassword(e?.target?.value ?? "")
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Old Password"}
                          name={"old_pass"}
                          required={true}
                          type={"text"}
                          onHandleChange={(e) =>
                            setOldPassword(e?.target?.value ?? "")
                          }
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link to="/dashboard" role="menuitem" style={cancelBtn}>
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li aria-hidden="true" style={{ display: "none;" }}>
                      <a
                        href="javascript:void(0)"
                        role="menuitem"
                        onClick={() => onSubmit()}
                      >
                        {loader ? <LoaderSpinner /> : "Save"}
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
