import React from "react";
// import { Link } from "react-router-dom";
import DotsPNG from "../../../assets/images/dots.png";
import AboutJPG from "../../../assets/images/about-img.jpg";
import CountUp from "react-countup";
import OwlCarousel from "react-owl-carousel";
import Slider1 from "../../../assets/images/slider-img1.jpg";
import Slider2 from "../../../assets/images/slider-img2.jpg";
import Team1 from "../../../assets/images/team-img1.jpg";
import Team2 from "../../../assets/images/team-img2.jpg";
import Team3 from "../../../assets/images/team-img3.jpg";
import Team4 from "../../../assets/images/team-img4.jpg";
import client1 from "../../../assets/images/sohan.jpg";
import client2 from "../../../assets/images/uma.JPG";
import client3 from "../../../assets/images/satish.JPG";
import client4 from "../../../assets/images/001.jpg";
import client5 from "../../../assets/images/002.jpg";
import client6 from "../../../assets/images/003.jpg";
import client7 from "../../../assets/images/testi-1.jpg";
import client8 from "../../../assets/images/testi-2.jpg";
import client9 from "../../../assets/images/testi-3.jpg";
import client10 from "../../../assets/images/3.jpg";
import client11 from "../../../assets/images/4.jpg";
import client12 from "../../../assets/images/5.jpg";
import client13 from "../../../assets/images/2.jpg";
import plan1 from "../../../assets/images/plan4.png";
import img1 from "../../../assets/images/img1.png";
import img2 from "../../../assets/images/img2.png";
import img3 from "../../../assets/images/img3.png";
import img4 from "../../../assets/images/sectionFooter.png";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
// Import Swiper styles
import "swiper/css";
// import "./styles.css";
import { Navigation, EffectCube } from "swiper/modules";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
export const Home = () => {
  return (
    <>
      {/* <!-- Quote Section --> */}
      {/* <div className="quote-part mfp-hide" id="quote-popup">
        <div className="container">
          <div className="section-title">
            {" "}
            <span className="section-span">Get A Quote</span>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <form className="quote-form">
                <div className="row">
                  <div className="col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="col-md">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Subject"
                      required
                    />
                  </div>
                  <div className="col-md">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Job Tittle"
                      required
                    />
                  </div>
                </div>
                <div className="form-group">
                  <textarea
                    className="form-control"
                    placeholder="Message"
                    rows="4"
                    required
                  ></textarea>
                </div>
                <button type="submit" className="btn">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- End Header Area -->  */}
      {/* <!-- Start Slider Area --> */}
      <section className="hero-slider hero-style-1">
        <Swiper
          className="swiper-container"
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          navigation={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Navigation, EffectCube]}
        >
          {/* <div className="swiper-container"> */}
          <div className="swiper-wrapper">
            <SwiperSlide>
              <div
                className="slide-inner slide-bg-image"
                style={{ backgroundImage: `url(${Slider1})` }}
                // data-background="images/slider-img1.jpg"
              >
                <div className="container">
                  <div data-swiper-parallax="300" className="slide-title">
                    <h2>WELCOME TO GMFX GLOBAL I LIMITED</h2>
                  </div>
                  <div data-swiper-parallax="400" className="slide-text">
                    <p>GMFX GLOBAL I LIMITED</p>
                  </div>
                  <div className="clearfix"></div>
                  <div
                    data-swiper-parallax="500"
                    className="slide-btns services-btn"
                  >
                    {" "}
                    <a href="services.html" className="bttn">
                      Login
                    </a>{" "}
                  </div>
                </div>
              </div>
              {/* <!-- end slide-inner -->  */}
            </SwiperSlide>
            {/* <!-- end swiper-slide --> */}

            <SwiperSlide>
              <div
                className="slide-inner slide-bg-image"
                // data-background="images/slider-img2.jpg"
                style={{ backgroundImage: `url(${Slider2})` }}
              >
                <div className="container">
                  <div data-swiper-parallax="300" className="slide-title">
                    <h2>WELCOME TO GMFX GLOBAL I LIMITED</h2>
                  </div>
                  <div data-swiper-parallax="400" className="slide-text">
                    <p>GMFX GLOBAL I LIMITED</p>
                  </div>
                  <div className="clearfix"></div>
                  <div
                    data-swiper-parallax="500"
                    className="slide-btns services-btn"
                  >
                    {" "}
                    <a href="services.html" className="bttn">
                      Login
                    </a>{" "}
                  </div>
                </div>
              </div>
              {/* <!-- end slide-inner -->  */}
            </SwiperSlide>
            {/* <!-- end swiper-slide -->  */}
          </div>
          {/* <!-- end swiper-wrapper -->  */}
          {/* <!-- swipper controls --> */}
          {/* <div className="swiper-pagination"></div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div> */}
          {/* </div> */}
        </Swiper>
      </section>
      {/* <!-- End Slider Area -->  */}
      {/* <!-- Start Feature section Area --> */}
      <section className="feature-section">
        <div className="container">
          <div className="feature-inner">
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                {/* <!-- Start single services block --> */}
                <div className="single-services-block">
                  <div className="inner-box">
                    <div>
                      <img src={img1} alt="" />
                    </div>
                    <h3 className="text-center">
                      <a href="service-details.html">Secured Platform</a>
                    </h3>
                    <div
                      className="text text-center"
                      style={{
                        letterSpacing: "0.5px",
                      }}
                    >
                      GMFX provides investors with a safe and reliable
                      environment to grow their wealth, ensuring that their
                      investments are protected through robust security measures
                      and transparent operations.
                    </div>
                    {/* <div className="read-more-btn">
                      <a href="service-details.html" className="theme-btn">
                        Read More <span className="icon-right-arrow2"></span>
                      </a>
                    </div> */}
                  </div>
                </div>
                {/* <!-- End single services block -->  */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {/* <!-- Start single services block --> */}
                <div className="single-services-block active">
                  <div className="inner-box">
                    <div>
                      <img src={img3} alt="" />
                    </div>
                    <h3 className="text-center">
                      <a href="service-details.html">
                        Trained Professional Team
                      </a>
                    </h3>
                    <div
                      className="text text-center"
                      style={{
                        letterSpacing: "0.5px",
                      }}
                    >
                      Our trained professional team is dedicated to delivering
                      top-tier expertise and service in every project, ensuring
                      quality and precision at every step.
                    </div>
                    {/* <div className="read-more-btn">
                      <a href="service-details.html" className="theme-btn">
                        Read More <span className="icon-right-arrow2"></span>
                      </a>
                    </div> */}
                  </div>
                </div>
                {/* <!-- End single services block -->  */}
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                {/* <!-- Start single services block --> */}
                <div className="single-services-block">
                  <div className="inner-box">
                    <div>
                      <img src={img2} alt="" />
                    </div>
                    <h3 className="text-center">
                      <a href="service-details.html">
                        Suitable For Both Small and Large Scale Business
                      </a>
                    </h3>
                    <div
                      className="text text-center"
                      style={{
                        letterSpacing: "0.5px",
                      }}
                    >
                      The solution is designed to cater to the needs of both all
                      type of businesses, offering flexibility and scalability
                      to ensure optimal performance .
                    </div>
                    {/* <div className="read-more-btn">
                      <a href="service-details.html" className="theme-btn">
                        Read More <span className="icon-right-arrow2"></span>
                      </a>
                    </div> */}
                  </div>
                </div>
                {/* <!-- End single services block -->  */}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Feature section Area -->  */}
      {/* <!-- Start Feature section Area --> */}
      <section className="about-sec">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-main-title">
              <h2>About Us</h2>
            </div>
            <div className="em_bar_bg"></div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="about-content-block">
                <h3>GMFX GLOBAL I LIMITED</h3>
                <div className="text mb-40">
                  <p
                    style={{
                      textAlign: "left",
                      letterSpacing: "0.5px",
                    }}
                  >
                    Have you ever thought about financial freedom? Do you have a
                    plan on how to get financial freedom? A lot of people out
                    here are chasing financial freedom but for lack of
                    information they don't know the steps to take. Finding
                    yourself on this page is not coincidence, rather it is a
                    signal that financial freedom is only steps away if you make
                    the right choice, the right decision by joining Investment
                    Company today to financial freedom. A marketing strategy
                    will help you focus.
                    <br />
                    It will identify the different ways you can talk to your
                    customers and concentrate on the ones that will create most
                    sales
                  </p>
                  {/* <ul className="list-style-one-edu">
                    <li>Arts Programs</li>
                    <li>Online skilled Courses</li>
                    <li>Online Course Description</li>
                    <li>Successful Growth In our Institution</li>
                  </ul> */}
                </div>
                <div className="link-btn mb-30">
                  <a href="#" className="bttn">
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-image-block about-img">
                <div className="inner-box">
                  <div className="image">
                    {" "}
                    <img src={DotsPNG} alt="about bg" />{" "}
                    <img
                      className="float-bob-y"
                      src={AboutJPG}
                      alt="about Img"
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Feature section Area -->  */}
      {/* <!-- Start Counter Sec Area --> */}
      <div className="counter-sec-main">
        <div className="container">
          <div className="row">
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-sec text-center">
                <div className="animated-bg">
                  <i></i>
                  <i></i>
                  <i></i>{" "}
                </div>
                <i className="count-icon icon-profile-male"></i>{" "}
                <span className="count-nos" id="target">
                  <CountUp end={580} duration={5} />
                </span>
                <p className="count-lable">Running Days</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-sec text-center">
                <div className="animated-bg">
                  <i></i>
                  <i></i>
                  <i></i>{" "}
                </div>
                <i className="count-icon icon-strategy"></i>{" "}
                <span className="count-nos" id="target2">
                  <CountUp end={4000} duration={5} />+
                </span>
                <p className="count-lable">Total Accounts</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-sec text-center">
                <div className="animated-bg">
                  <i></i>
                  <i></i>
                  <i></i>{" "}
                </div>
                <i className="count-icon icon-puzzle"></i>{" "}
                <span className="count-nos" id="target3">
                  <CountUp end={7000} duration={5} />+
                </span>
                <p className="count-lable">Visitors Online</p>
              </div>
            </div>
            <div className="col-md-3 col-sm-6 col-xs-6">
              <div className="counter-sec text-center">
                <div className="animated-bg">
                  <i></i>
                  <i></i>
                  <i></i>{" "}
                </div>
                <i className="count-icon icon-trophy"></i>{" "}
                <span className="count-nos" id="target4">
                  <h6>20-08-2024</h6>
                  {/* <CountUp end={128560} duration={5} /> */}
                </span>
                <p className="count-lable">Last Update</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Counter Sec Area -->  */}
      {/* <!-- Start Services Sec Area --> */}
      {/* <section className="services-sec">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-sub-title">
              <h6>Our Services </h6>
            </div>
            <div className="section-main-title">
              <h2>Our Awesome Services</h2>
            </div>
            <div className="em_bar_bg"></div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="single_service">
                <div className="single_service_inner">
                  <div className="icon-edu">
                    {" "}
                    <i className="bx bx-book"></i>
                  </div>
                  <h4>Awesome Courses</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_service">
                <div className="single_service_inner">
                  <div className="icon-edu">
                    {" "}
                    <i className="bx bx-plus-medical"></i>
                  </div>
                  <h4>Medical Service</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_service">
                <div className="single_service_inner">
                  <div className="icon-edu">
                    {" "}
                    <i className="bx bx-football"></i>
                  </div>
                  <h4>Many Sports</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_service">
                <div className="single_service_inner">
                  <div className="icon-edu">
                    {" "}
                    <i className="bx bx-globe"></i>
                  </div>
                  <h4>Online Courses</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_service">
                <div className="single_service_inner">
                  <div className="icon-edu">
                    {" "}
                    <i className="bx bx-car"></i>
                  </div>
                  <h4>Transportation</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="single_service">
                <div className="single_service_inner">
                  <div className="icon-edu">
                    {" "}
                    <i className="bx bx-phone"></i>
                  </div>
                  <h4>Customer Support</h4>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Services Sec Area -->  */}
      {/* <!-- Start Our Team Sec Area --> */}
      <section className="our-team-sec">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-main-title">
              <h2>Our Awesome Team</h2>
            </div>
            <div className="em_bar_bg"></div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              {/* <!-- Start Single Team Sec --> */}
              <div className="single_team mb-4">
                <div className="single_team_thumb">
                  {" "}
                  <img
                    src={Team1}
                    alt=""
                    style={{
                      height: "255px",
                      width: "255px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="single_team_icon">
                    {" "}
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>{" "}
                  </div>
                </div>
                <div className="single_team_content">
                  <h4>Mr.Bimal Rawat</h4>
                  <span>CEO GMFX Global</span>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              {/* <!-- Start Single Team Sec --> */}
              <div className="single_team mb-4">
                <div className="single_team_thumb">
                  {" "}
                  <img
                    src={Team2}
                    alt=""
                    style={{
                      height: "255px",
                      width: "255px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="single_team_icon">
                    {" "}
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>{" "}
                  </div>
                </div>
                <div className="single_team_content">
                  <h4>Mrs. Ruby Rawat</h4>
                  <span>Director, GMFX Global</span>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              {/* <!-- Start Single Team Sec --> */}
              <div className="single_team mb-4">
                <div className="single_team_thumb">
                  {" "}
                  <img
                    src={Team3}
                    alt=""
                    style={{
                      height: "255px",
                      width: "255px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="single_team_icon">
                    {" "}
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>{" "}
                  </div>
                </div>
                <div className="single_team_content">
                  <h4>Mr. Anup Kandari</h4>
                  <span>Trainer, GMFX Global</span>{" "}
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              {/* <!-- Start Single Team Sec --> */}
              <div className="single_team mb-4">
                <div className="single_team_thumb">
                  {" "}
                  <img
                    src={Team4}
                    alt=""
                    style={{
                      height: "255px",
                      width: "255px",
                      borderRadius: "50%",
                    }}
                  />
                  <div className="single_team_icon">
                    {" "}
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-linkedin"></i>
                    </a>{" "}
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>{" "}
                  </div>
                </div>
                <div className="single_team_content">
                  <h4>Mr. Jitendra Pandey</h4>
                  <span>Trader, GMFX Global</span>{" "}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Our Team Sec Area --> */}
      {/* <!-- Start Pricing Sec Area --> */}
      <section className="pricing-sec">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-main-title">
              <h2>Our Best Plans</h2>
            </div>
            <div className="em_bar_bg"></div>
          </div>
          <div className="row">
            <div class="inner-content-wrapper blog-sec blog-area">
              <div class="container">
                <div class="row">
                  <div class="col-xl-12 col-lg-12 mb-30">
                    <div class="blog-details">
                      <div class="blogs-wrapper mb-35 pos-rel">
                        <div class="blog-img">
                          {" "}
                          <a href="javascript:void(0)">
                            <img src={plan1} alt="" />
                          </a>
                        </div>
                      </div>
                      <div class="post-text mb-20">
                        <blockquote>
                          <p>
                            GMFX GLOBAL I LIMITED is an authorized partner of
                            Master Trust, providing a trading platform to our
                            customers. Additionally, we educate individuals
                            about stock market trading and offer high-quality
                            trading signals to our potential customers.
                          </p>
                          {/* <footer>- Mr. Bimal Rawat</footer> */}
                          <br /> Click to Open demat Account Here :-
                          <h3>
                            <a
                              href="https://mastertrust.getseto.com/mobile/?emp_code=S7AZT&br_code=B7AZT"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              https://mastertrust.getseto.com/mobile/?emp_code=S7AZT&br_code=B7AZT
                            </a>
                          </h3>
                        </blockquote>

                        <p
                          style={{
                            textAlign: "end",
                          }}
                        >
                          <a
                            href="https://mastertrust.getseto.com/mobile/?emp_code=S7AZT&br_code=B7AZT"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={img4}
                              alt=""
                              style={{
                                width: "20vw",
                              }}
                            />
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Pricing Sec Area --> */}
      {/* <!-- Start Testimonials Sec Area --> */}
      <section className="testimonials-sec">
        <div className="dots"></div>
        <div className="container">
          <div className="section-title text-center">
            <div className="section-main-title">
              <h2> Testimonials </h2>
            </div>
            <div className="em_bar_bg"></div>
          </div>
          <div className="row">
            <div className="col-12">
              <OwlCarousel
                id="testimonial-carousel"
                className="testimonial-carousel box-shadow owl-carousel"
                loop={true}
                nav={true}
                items={2}
                margin={10}
                responsive={{
                  0: {
                    items: 1,
                    autoHeight: true,
                  },
                  768: {
                    items: 1,
                  },
                  980: {
                    items: 2,
                  },
                  1240: {
                    items: 2,
                  },
                }}
              >
                {/* <!-- Start Testimonials Item 1 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client1} alt="img" />
                  <div className="testi-content">
                    <p>
                      "GMFX Global has been a game-changer for our investment
                      strategy. Their expert guidance and innovative tools have
                      consistently delivered impressive returns, giving us the
                      confidence to grow our portfolio. The personalized service
                      and attention to detail make us feel valued as clients. We
                      highly recommend GMFX Global to anyone looking to achieve
                      financial success."
                    </p>
                    <h3>Naresh Kumar</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 1 --> */}

                {/* <!-- Start Testimonials Item 2 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client2} alt="img" />
                  <div className="testi-content">
                    <p>
                      "Partnering with GMFX Global has been one of the best
                      decisions we've made for our financial future. Their
                      team’s expertise and commitment to our success have
                      transformed our investment experience. We appreciate their
                      transparent approach and the steady growth we've seen in
                      our portfolio. GMFX Global truly stands out in the
                      industry, and we couldn’t be happier with the results."
                    </p>
                    <h3>Uma Kant Pathak</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 2 --> */}

                {/* <!-- Start Testimonials Item 3 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client3} alt="img" />
                  <div className="testi-content">
                    <p>
                      "GMFX Global has exceeded our expectations with their
                      exceptional service and investment strategies. Their
                      knowledgeable team provides clear insights and tailored
                      solutions that have significantly boosted our financial
                      confidence. We're thrilled with the consistent returns and
                      the peace of mind that comes with knowing our investments
                      are in expert hands."
                    </p>
                    <h3>Satish khulbe</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 3 --> */}

                {/* <!-- Start Testimonials Item 4 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client4} alt="img" />
                  <div className="testi-content">
                    <p>
                      "GMFX Global I Limited has been exceptional in managing my
                      investments. Their knowledgeable team and personalized
                      approach have consistently exceeded my expectations. I
                      trust them completely with my financial future."
                    </p>
                    <h3>Shailja Asthana</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 4 --> */}

                {/* <!-- Start Testimonials Item 5 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client5} alt="img" />
                  <div className="testi-content">
                    <p>
                      "Choosing GMFX Global I Limited was the best decision I've
                      made for my investments. Their strategic insights and
                      excellent customer service have led to substantial growth
                      in my portfolio. Highly recommended!"
                    </p>
                    <h3>Jagdeep Arya</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 5 --> */}

                {/* <!-- Start Testimonials Item 6 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client6} alt="img" />
                  <div className="testi-content">
                    <p>
                      "Working with GMFX Global I Limited has been a
                      game-changer for my finances. Their expert advice and
                      dedication to my goals have resulted in impressive
                      returns. I'm grateful for their support and
                      professionalism."
                    </p>
                    <h3>Karan Mishra</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 6 --> */}

                {/* <!-- Start Testimonials Item 7 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client7} alt="img" />
                  <div className="testi-content">
                    <p>
                      "GMFX Global I Limited offers top-notch investment
                      services. Their team's expertise and commitment to client
                      satisfaction have helped me achieve significant financial
                      milestones. I highly recommend their services."
                    </p>
                    <h3>Lokesh Gupta</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 7 --> */}

                {/* <!-- Start Testimonials Item 8 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client8} alt="img" />
                  <div className="testi-content">
                    <p>
                      "I'm extremely satisfied with GMFX Global I Limited. Their
                      personalized investment strategies and excellent
                      communication have made a significant difference in my
                      financial growth. They are a trusted partner in my
                      financial journey."
                    </p>
                    <h3>Mahesh Mehta</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 8 --> */}

                {/* <!-- Start Testimonials Item 9 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client9} alt="img" />
                  <div className="testi-content">
                    <p>
                      "GMFX Global I Limited has consistently delivered
                      excellent results for my investments. Their professional
                      team and transparent approach have built my trust and
                      confidence in their services. I highly recommend them."
                    </p>
                    <h3>Shivani Singh</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 9 --> */}

                {/* <!-- Start Testimonials Item 10 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client10} alt="img" />
                  <div className="testi-content">
                    <p>
                      "Since partnering with GMFX Global I Limited, my
                      investment portfolio has seen remarkable growth. Their
                      expert guidance and dedicated support make them an
                      invaluable investment partner. I'm very pleased with their
                      services."
                    </p>
                    <h3>Anjali Verma</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 10 --> */}

                {/* <!-- Start Testimonials Item 11 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client11} alt="img" />
                  <div className="testi-content">
                    <p>
                      "GMFX Global I Limited provides exceptional investment
                      management services. Their team's expertise and
                      personalized approach have significantly enhanced my
                      financial standing. I highly recommend them for their
                      outstanding performance."
                    </p>
                    <h3>Virendra Kumar</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 11 --> */}

                {/* <!-- Start Testimonials Item 12 --> */}
                <div className="testi-item d-flex align-items-center">
                  {" "}
                  <img src={client12} alt="img" />
                  <div className="testi-content">
                    <p>
                      "I'm impressed with GMFX Global I Limited's investment
                      strategies and client-focused approach. Their professional
                      team has helped me achieve consistent and substantial
                      returns. I trust them completely with my financial
                      investments."
                    </p>
                    <h3>Anjana Tewatia</h3>
                    <ul className="rattings">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                    <span>Investor</span>{" "}
                  </div>
                  <i className="fa fa-quote-right"></i>{" "}
                </div>
                {/* <!-- End Testimonials Item 12 --> */}
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- End Testimonials Sec Area --> */}
      {/* <!-- Start Blog Sec Area --> */}
      {/* <section className="blog-sec">
        <div className="container">
          <div className="section-title text-center">
            <div className="section-sub-title">
              <h6> our Blog </h6>
            </div>
            <div className="section-main-title">
              <h2> Our Latest Articles </h2>
            </div>
            <div className="em_bar_bg"></div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6">
              <div className="blog-card">
                <div className="blog-img">
                  {" "}
                  <a href="blog-single.html">
                    <img src={BlogAuthorOne} alt="" />
                  </a>
                  <div className="blog-date">
                    {" "}
                    <span>21 Feb</span>{" "}
                  </div>
                </div>
                <div className="blog-text">
                  <h3>
                    <a href="blog-single.html">
                      7 Education Reports That Defined
                    </a>
                  </h3>
                  <div className="post-info">
                    {" "}
                    <img src={BlogAuthorOne} alt="" />{" "}
                    <a href="#" className="ml-3">
                      <p>Aikin Ward</p>
                    </a>{" "}
                    <a href="blog-single.html" className="blog-btn">
                      {" "}
                      Read More <i className="fa fa-angle-right"></i>
                    </a>{" "}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-card">
                <div className="blog-img">
                  {" "}
                  <a href="blog-single.html">
                    <img src={BlogAuthorTwo} alt="" />
                  </a>
                  <div className="blog-date">
                    {" "}
                    <span>21 Feb</span>{" "}
                  </div>
                </div>
                <div className="blog-text">
                  <h3>
                    <a href="blog-single.html">
                      7 Education Reports That Defined
                    </a>
                  </h3>
                  <div className="post-info">
                    {" "}
                    <img src="images/blog-author-2.png" alt="" />{" "}
                    <a href="#" className="ml-3">
                      <p>Aikin Ward</p>
                    </a>{" "}
                    <a href="blog-single.html" className="blog-btn">
                      {" "}
                      Read More <i className="fa fa-angle-right"></i>
                    </a>{" "}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="blog-card">
                <div className="blog-img">
                  {" "}
                  <a href="blog-single.html">
                    <img src={BlogAuthorThree} alt="" />
                  </a>
                  <div className="blog-date">
                    {" "}
                    <span>21 Feb</span>{" "}
                  </div>
                </div>
                <div className="blog-text">
                  <h3>
                    <a href="blog-single.html">
                      7 Education Reports That Defined
                    </a>
                  </h3>
                  <div className="post-info">
                    {" "}
                    <img src="images/blog-author-3.png" alt="" />{" "}
                    <a href="#" className="ml-3">
                      <p>Aikin Ward</p>
                    </a>{" "}
                    <a href="blog-single.html" className="blog-btn">
                      {" "}
                      Read More <i className="fa fa-angle-right"></i>
                    </a>{" "}
                  </div>
                </div>
                <div></div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- End Blog Sec Area --> */}
      {/* <!-- Start CTA Sec Area --> */}
      {/* <div className="cta-sec">
        <div className="container">
          <div className="wrapper-box">
            <h2>Are You Ready to Buy This Template! </h2>
            <div className="read-more">
              <a href="javascript:void(0)" className="bttn">
                Buy Now
              </a>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- End CTA Sec Area --> */}
      {/* <!-- Start Brand Wrapper --> */}
      {/* <div className="brand-wrapper">
        <div className="container">
          <div className="owl-carousel owl-theme brand-slider">
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-1.png" alt="" title="" />
              </a>{" "}
            </div>
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-2.png" alt="" title="" />
              </a>{" "}
            </div>
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-3.png" alt="" title="" />
              </a>{" "}
            </div>
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-4.png" alt="" title="" />
              </a>{" "}
            </div>
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-2.png" alt="" title="" />
              </a>{" "}
            </div>
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-1.png" alt="" title="" />
              </a>{" "}
            </div>
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-3.png" alt="" title="" />
              </a>{" "}
            </div>
            <div className="item">
              {" "}
              <a target="_blank" href="JavaScript:Void(0)">
                {" "}
                <img src="images/client-logo-4.png" alt="" title="" />
              </a>{" "}
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- End Brand Wrapper --> */}
    </>
  );
};
