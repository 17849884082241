/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Logo from "../../images/logo-dark.png";
// import MiniLogoTet from "../../images/logo-dark-text.png";
import AvatarSeven from "../../images/avatar/7.jpg";
import RoundCircle from "../../images/user2-160x160.jpg";
import UserThree from "../../images/user3-128x128.jpg";
import UserFour from "../../images/user4-128x128.jpg";
import GMXLogo from "../../assets/images/gmfx-logo.png";
import { Link, useNavigate } from "react-router-dom";
import { getUserInfo } from "../../utils/common";
import { useDispatch, useSelector } from "react-redux";
export const Header = () => {
  const dispatch = useDispatch();
  const reducerStore = useSelector((state) => state?.sidebarToggle?.isToggle);
  const userData = JSON.parse(getUserInfo());
  const navigation = useNavigate();
  const logOut = () => {
    localStorage.clear();
    navigation("/");
  };
  const sidebarToggle = () => {
    const tValue = !reducerStore;
    dispatch({
      type: "is_toggle",
      toggle: tValue,
    });
  };

  return (
    <header className="main-header">
      {/* <!-- Logo --> */}
      <a href="index.html" className="logo">
        {/* <!-- mini logo --> */}
        <div className="logo-mini">
          <span className="light-logo">
            <img src={GMXLogo} alt="logo" className="gmfx-img" />
          </span>
          <span className="dark-logo">
            <img src={GMXLogo} alt="logo" className="gmfx-img" />
          </span>
        </div>
        {/* <!-- logo--> */}
        <div className="logo-lg">
          <span className="light-logo">
            GMFX
            <img src={GMXLogo} alt="logo_gmfx" />
          </span>
          GMFX
          <span className="dark-logo">
            <img src={GMXLogo} alt="logo_gmfx" />
          </span>
        </div>
      </a>
      {/* <!-- Header Navbar --> */}
      <nav className="navbar navbar-static-top">
        {/* <!-- Sidebar toggle button--> */}
        <div className="ml-20">
          <a
            href="javascript:void(0)"
            className="sidebar-toggle"
            data-toggle="push-menu"
            role="button"
            onClick={() => sidebarToggle()}
          >
            <i className="ti-align-left"></i>
          </a>

          {/* <Link
            to="/dashboard"
            data-provide="fullscreen"
            className="sidebar-toggle"
            title="Full Screen"
          >
            <i className="mdi mdi-crop-free"></i>
          </Link> */}
        </div>

        <div className="navbar-custom-menu r-side">
          <ul className="nav navbar-nav">
            {/* <!-- full Screen --> */}
            {/* <li className="search-bar">
              <div className="lookup lookup-circle lookup-right">
                <input type="text" name="s" />
              </div>
            </li> */}
            {/* <!-- Messages --> */}
            <li className="dropdown messages-menu">
              {/* <a
                href="javascript:void(0)"
                className="dropdown-toggle"
                data-toggle="dropdown"
                title="Messages"
              >
                <i className="mdi mdi-email"></i>
              </a> */}
              <ul className="dropdown-menu animated bounceIn">
                <li className="header">
                  <div className="p-20">
                    <div className="flexbox">
                      <div>
                        <h4 className="mb-0 mt-0">Messages</h4>
                      </div>
                      <div>
                        <a href={void 0} className="text-danger">
                          Clear All
                        </a>
                      </div>
                    </div>
                  </div>
                </li>
                <li>
                  {/* <!-- inner menu: contains the actual data --> */}
                  <ul className="menu sm-scrol">
                    <li>
                      <a href={void 0}>
                        <div className="pull-left">
                          <img
                            src={RoundCircle}
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Lorem Ipsum
                            <small>
                              <i className="fa fa-clock-o"></i> 15 mins
                            </small>
                          </h4>
                          <span>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit.
                          </span>
                        </div>
                      </a>
                    </li>
                    {/* <!-- end message --> */}
                    <li>
                      <a href={void 0}>
                        <div className="pull-left">
                          <img
                            src={UserThree}
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Nullam tempor
                            <small>
                              <i className="fa fa-clock-o"></i> 4 hours
                            </small>
                          </h4>
                          <span>
                            Curabitur facilisis erat quis metus congue viverra.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <div className="pull-left">
                          <img
                            src={UserFour}
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Proin venenatis
                            <small>
                              <i className="fa fa-clock-o"></i> Today
                            </small>
                          </h4>
                          <span>
                            Vestibulum nec ligula nec quam sodales rutrum sed
                            luctus.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <div className="pull-left">
                          <img
                            src="../images/user3-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Praesent suscipit
                            <small>
                              <i className="fa fa-clock-o"></i> Yesterday
                            </small>
                          </h4>
                          <span>
                            Curabitur quis risus aliquet, luctus arcu nec,
                            venenatis neque.
                          </span>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <div className="pull-left">
                          <img
                            src="../images/user4-128x128.jpg"
                            className="rounded-circle"
                            alt="User Image"
                          />
                        </div>
                        <div className="mail-contnet">
                          <h4>
                            Donec tempor
                            <small>
                              <i className="fa fa-clock-o"></i> 2 days
                            </small>
                          </h4>
                          <span>
                            Praesent vitae tellus eget nibh lacinia pretium.
                          </span>
                        </div>
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a href={void 0}>See all e-Mails</a>
                </li>
              </ul>
            </li>
            {/* <!-- Notifications --> */}
            <li className="dropdown notifications-menu">
              {/* <a
                href={void 0}
                className="dropdown-toggle"
                data-toggle="dropdown"
                title="Notifications"
              >
                <i className="mdi mdi-bell"></i>
              </a> */}
              <ul className="dropdown-menu animated bounceIn">
                <li className="header">
                  <div className="p-20">
                    <div className="flexbox">
                      <div>
                        <h4 className="mb-0 mt-0">Notifications</h4>
                      </div>
                      <div>
                        <a href={void 0} className="text-danger">
                          Clear All
                        </a>
                      </div>
                    </div>
                  </div>
                </li>

                <li>
                  {/* <!-- inner menu: contains the actual data --> */}
                  <ul className="menu sm-scrol">
                    <li>
                      <a href={void 0}>
                        <i className="fa fa-users text-info"></i> Curabitur id
                        eros quis nunc suscipit blandit.
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <i className="fa fa-warning text-warning"></i> Duis
                        malesuada justo eu sapien elementum, in semper diam
                        posuere.
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <i className="fa fa-users text-danger"></i> Donec at
                        nisi sit amet tortor commodo porttitor pretium a erat.
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <i className="fa fa-shopping-cart text-success"></i> In
                        gravida mauris et nisi
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <i className="fa fa-user text-danger"></i> Praesent eu
                        lacus in libero dictum fermentum.
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <i className="fa fa-user text-primary"></i> Nunc
                        fringilla lorem
                      </a>
                    </li>
                    <li>
                      <a href={void 0}>
                        <i className="fa fa-user text-success"></i> Nullam
                        euismod dolor ut quam interdum, at scelerisque ipsum
                        imperdiet.
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="footer">
                  <a href={void 0}>View all</a>
                </li>
              </ul>
            </li>

            {/* <!-- User Account--> */}
            <li className="dropdown user user-menu">
              <a
                href={void 0}
                className="dropdown-toggle"
                data-toggle="dropdown"
                title="User"
              >
                <img
                  src={AvatarSeven}
                  className="user-image rounded-circle"
                  alt="User Image"
                />
              </a>
              <ul className="dropdown-menu animated flipInX">
                {/* <!-- User image --> */}
                <li
                  className="user-header bg-img"
                  //   style={{"background-image: url(../images/user-info.jpg)"}}
                  data-overlay="3"
                >
                  <div className="flexbox align-self-center">
                    <img
                      src={AvatarSeven}
                      className="float-left rounded-circle"
                      alt="User Image"
                    />
                    <h4 className="user-name align-self-center">
                      <span>{userData?.name ?? "Er"}</span>
                      <small>
                        <p>{userData?.email ?? "ER"}</p>
                      </small>
                    </h4>
                  </div>
                </li>
                {/* <!-- Menu Body --> */}
                <li className="user-body">
                  {/* <a className="dropdown-item" href={void 0}>
                    <i className="ion ion-person"></i> My Profile
                  </a> */}
                  {/* <a className="dropdown-item" href={void 0}>
                    <i className="ion ion-bag"></i> My Balance
                  </a> */}
                  {/* <a className="dropdown-item" href={void 0}>
                    <i className="ion ion-email-unread"></i> Inbox
                  </a> */}
                  {/* <div className="dropdown-divider"></div> */}
                  {/* <a className="dropdown-item" href={void 0}>
                    <i className="ion ion-settings"></i> Account Setting
                  </a> */}
                  {/* <div className="dropdown-divider"></div> */}
                  <a
                    style={{ cursor: "pointer" }}
                    className="dropdown-item"
                    href={void 0}
                    onClick={() => logOut()}
                  >
                    <i className="ion-log-out"></i> Logout
                  </a>
                  {/* <div className="dropdown-divider"></div>
                  <div className="p-10">
                    <a
                      href={void 0}
                      className="btn btn-sm btn-rounded btn-success"
                    >
                      View Profile
                    </a>
                  </div> */}
                </li>
              </ul>
            </li>

            {/* <!-- Control Sidebar Toggle Button --> */}
            {/* <li>
                <a href={void(0)} data-toggle="control-sidebar" title="Setting"><i className="fa fa-cog fa-spin"></i></a>
              </li> */}
          </ul>
        </div>
      </nav>
    </header>
  );
};
