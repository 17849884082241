/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import UserMing from "../../images/user5-128x128.jpg";
import { useNavigate, Link } from "react-router-dom";
import "./layout-helper/sidebar-style.css";
import { useSelector, useDispatch } from "react-redux";
import { getUserInfo } from "../../utils/common";
export const Siderbar = () => {
  const userData = JSON.parse(getUserInfo());
  const location_path = useSelector(
    (state) => state?.sidebarPath?.storePath ?? null
  );
  const reducerStore = useSelector(
    (state) => state?.sidebarToggle?.isToggle ?? false
  );
  const [sideBarState, setSideBarState] = useState(false);
  const compWidth = window.innerWidth;
  const navigation = useNavigate();
  const dispath = useDispatch();
  const logOut = () => {
    localStorage.clear();
    navigation("/");
  };
  useEffect(() => {
    if (reducerStore) {
      const compWidth = window.innerWidth;
      if (compWidth > 766) {
        setSideBarState(true);
      }
    } else {
      setSideBarState(false);
    }
  }, [reducerStore]);

  const handleMenuClick = (index) => {
    dispath({
      type: "path_location",
      path: location_path === index ? null : index,
    });
  };

  const handleSubMenuClick = (e, menuIndex, location) => {
    e?.stopPropagation();
    dispath({ type: "path_location", path: menuIndex });
    navigation(`/${location}`);
    if (compWidth < 766) {
      removeSidebarOpenClass();
    }
  };
  const removeSidebarOpenClass = () => {
    const body = document.body;
    const currentClasses = body.className.split(" ");
    const newClasses = currentClasses.filter(
      (className) => className !== "sidebar-open"
    );
    body.className = newClasses.join(" ");
  };
  return (
    // <!-- Left side column. contains the logo and sidebar -->
    <aside className="main-sidebar">
      {/* <!-- sidebar--> */}
      <section className="sidebar position-relative">
        <div className="user-profile">
          <div className="ulogo">
            <a href="index.html">
              {/* <!-- logo for regular state and mobile devices --> */}
              <h3>
                <b>GMFX</b>Global
              </h3>
            </a>
          </div>
          <div className="profile-pic">
            <img src={UserMing} alt="user" />
            <div className="profile-info">
              <h4>{userData?.name ?? "Error"}</h4>
              {/* <div className="list-icons-item dropdown">
                <a
                  href="#"
                  className="list-icons-item dropdown-toggle"
                  data-toggle="dropdown"
                  onClick={(e) => e.preventDefault()}
                >
                  <span className="badge badge-ring fill badge-primary mr-2"></span>
                  Online
                </a>
                <div className="dropdown-menu dropdown-menu-right">
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={(e) => e.preventDefault()}
                  >
                    Update data
                  </a>
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={(e) => e.preventDefault()}
                  >
                    Detailed log
                  </a>
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={(e) => e.preventDefault()}
                  >
                    Statistics
                  </a>
                  <a
                    href="#"
                    className="dropdown-item"
                    onClick={(e) => e.preventDefault()}
                  >
                    Clear list
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="multinav">
          <div
            className="scrollable ps-theme-default"
            data-ps-id="58733866-1715-a9a9-0309-ccfb26c55a2e"
            style={
              sideBarState
                ? {
                    height: "100%",
                    overflowY: "visible",
                  }
                : { height: "100%", overflowY: "auto" }
            }
          >
            {/* <!-- sidebar menu--> */}
            <ul className="sidebar-menu" data-widget="tree">
              <li
                className={location_path === 0 ? "active" : ""}
                onClick={() => handleMenuClick(0)}
              >
                <Link to="/dashboard" onClick={() => removeSidebarOpenClass()}>
                  <i className="ti-dashboard"></i>
                  <span>Dashboard</span>
                </Link>
              </li>
              <li
                className={
                  location_path === 1 ? "treeview active menu-open" : "treeview"
                }
                onClick={() => handleMenuClick(1)}
              >
                <a
                  href={"javascript:(void);"}
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="ti-files"></i>
                  <span>Master</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul
                  className="treeview-menu"
                  style={
                    location_path === 1
                      ? { display: "block" }
                      : { display: "none" }
                  }
                  // style={location_path === 1 ? "sidebar-dn" : "sidebar-db"}
                >
                  <li onClick={(e) => handleSubMenuClick(e, 1, "profile")}>
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>Profile
                    </a>
                  </li>
                  <li
                    onClick={(e) => handleSubMenuClick(e, 1, "change_password")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>Change Password
                    </a>
                  </li>
                  <li
                    onClick={(e) =>
                      handleSubMenuClick(e, 1, "change_profile_pic")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>Change Profile Picture
                    </a>
                  </li>
                  <li onClick={(e) => handleSubMenuClick(e, 1, "support")}>
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>Support
                    </a>
                  </li>
                  <li
                    onClick={(e) =>
                      handleSubMenuClick(e, 1, "support_response")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>Support Response
                    </a>
                  </li>

                  <li onClick={(e) => e?.stopPropagation()}>
                    <Link to={"/investment_aggrement"} target="_blank">
                      <i className="ti-more"></i>Investment Agreement
                    </Link>
                  </li>
                  <li onClick={(e) => handleSubMenuClick(e, 1, "i_chard")}>
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>I Card
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={
                  location_path === 2 ? "treeview active menu-open" : "treeview"
                }
                onClick={() => handleMenuClick(2)}
              >
                <a
                  href="javascript:(void);"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="ti-view-list"></i>
                  <span>KYC / BANK</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul
                  className="treeview-menu"
                  style={
                    location_path === 2
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 2, "bank")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      {/* <i className="ti-email"></i> */}
                      {/* <span className="pull-right-container"> */}
                      <i className="ti-more"></i>Bank
                      {/* </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 2, "kyc")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      {/* <i className="ti-user"></i> */}
                      {/* <span className="pull-right-container"> */}
                      <i className="ti-more"></i>KYC
                      {/* </span> */}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={
                  location_path === 3 ? "treeview active menu-open" : "treeview"
                }
                onClick={() => handleMenuClick(3)}
              >
                <a
                  href="javascript:(void);"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="ti-pencil-alt"></i>
                  <span>Investment</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul
                  className="treeview-menu"
                  style={
                    location_path === 3
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 3, "investment_request")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>Investment Request
                      {/* <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 3, "action_history")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>Action History
                      {/* <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 3, "history")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>History
                      {/* <span className="pull-right-container">
                        <i className="fa fa-angle-right pull-right"></i>
                      </span> */}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={
                  location_path === 4 ? "treeview active menu-open" : "treeview"
                }
                onClick={() => handleMenuClick(4)}
              >
                <a
                  href="javascript:(void);"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="ti-bar-chart"></i>
                  <span>Main Wallet</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul
                  className="treeview-menu"
                  style={
                    location_path === 4
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 4, "main_wallet_summary")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      Main Wallet Summary
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 4, "payout_hisotry")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-stats-up"></i> */}
                      Payout History
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={
                  location_path === 5 ? "treeview active menu-open" : "treeview"
                }
                onClick={() => handleMenuClick(5)}
              >
                <a
                  href="javascript:(void);"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="ti-bar-chart"></i>
                  <span>Income Wallet</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul
                  className="treeview-menu"
                  style={
                    location_path === 5
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 5, "trading_bonus")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      Trading Bonus
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 5, "level_income")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-stats-up"></i> */}
                      Level Income
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 5, "trading_profit_income")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      Trading Profit Income
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 5, "club_income")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-stats-up"></i> */}
                      Club Income
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 5, "royalty_income")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      Royalty Income
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 5, "reward_income")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-stats-up"></i> */}
                      Reward Income
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 5, "award_rewards")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      Awards & Rewards
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 5, "club_report")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-stats-up"></i> */}
                      Club Report
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                className={
                  location_path === 6 ? "treeview active menu-open" : "treeview"
                }
                onClick={() => handleMenuClick(6)}
              >
                <a
                  href="javascript:(void);"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="ti-bar-chart"></i>
                  <span>My Network</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul
                  className="treeview-menu"
                  style={
                    location_path === 6
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 6, "my_direct")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      My Direct
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 6, "my_team")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-stats-up"></i> */}
                      My Team
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 6, "my_tree")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      My Tree
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 6, "team_business")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-stats-up"></i> */}
                      Team Business
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) => handleSubMenuClick(e, 6, "rank_status")}
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      {/* <i className="ti-pie-chart"></i> */}
                      Rank Status
                      {/* <span className="pull-right-container">
                        <i className="ti-more"></i>
                      </span> */}
                    </a>
                  </li>
                </ul>
              </li>
              {/* <li
                className={
                  location_path === 7 ? "treeview active menu-open" : "treeview"
                }
                onClick={() => handleMenuClick(7)}
              >
                <a
                  href="javascript:(void);"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="ti-bar-chart"></i>
                  <span>Front Admin</span>
                  <span className="pull-right-container">
                    <i className="fa fa-angle-right pull-right"></i>
                  </span>
                </a>
                <ul
                  className="treeview-menu"
                  style={
                    location_path === 7
                      ? { display: "block" }
                      : { display: "none" }
                  }
                >
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 7, "front-admin-slider")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      Slider
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 7, "front-admin-service")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      Service
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 7, "front-admin-updates")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      Updates
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 7, "front-admin-about-us")
                    }
                  >
                    <a
                      href="javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      About US
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(e, 7, "front-admin-testinomial")
                    }
                  >
                    <a
                      href="#javascript:(void);"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="ti-more"></i>
                      Testinomial
                    </a>
                  </li>
                  <li
                    className="treeview"
                    onClick={(e) =>
                      handleSubMenuClick(
                        e,
                        7,
                        "front-admin-contact-us-feedback"
                      )
                    }
                  >
                    <a href="#" onClick={(e) => e.preventDefault()}>
                      <i className="ti-more"></i>
                      Contact US Feedback
                    </a>
                  </li>
                </ul>
              </li> */}
              <li onClick={() => logOut()}>
                <Link to="/dashboard">
                  <i className="ti-dashboard"></i>
                  <span>Logout</span>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </aside>
  );
};
