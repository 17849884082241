import React from "react";
import { Navigate } from "react-router-dom";
import { FinalLayout } from "../component/Layout/final";
import { HomeLayout } from "../component/Layout/home-final-layout";
import useBodyClass from "../hooks/customBodyHook";
export const PrivateRoute = ({ component: Component, ...rest }) => {
  // Check if the user is authenticated
  const isAuthenticated = localStorage.getItem("token");
  useBodyClass("hold-transition sidebar-mini theme-classic fixed light-skin");
  return !!isAuthenticated ? (
    <FinalLayout>
      <Component {...rest} />
    </FinalLayout>
  ) : (
    <Navigate to="/" replace />
  );
};

export const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <HomeLayout>
      <Component {...rest} />
    </HomeLayout>
  );
};
