import React, { useEffect, useState } from "react";
import { FormHeader } from "../../small-components/formHeader";
import "./master-helper/id_card_holder.css";
import service from "../../../service";
import toastr from "toastr";
import { Audio } from "react-loader-spinner";
import moment from "moment";
export const ICard = () => {
  const [iCardData, setICardData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getICardData();
  }, []);
  const getICardData = async () => {
    setIsLoading(true);
    const res = await service.requestApi.iCard();
    if ("email" in res && "fullName" in res && "rankname" in res) {
      setIsLoading(false);
      setICardData(res);
    } else {
      setIsLoading(false);
      toastr.error("No data found or Error Occuried", "Error");
    }
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Master"} child={"I-Card"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">I-Card</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}

            <>
              <div className="box-body wizard-content">
                <form
                  className="validation-wizard wizard-circle wizard clearfix id_card_holder-form"
                  role="application"
                  id="steps-uid-2"
                  novalidate="novalidate"
                >
                  <div
                    className="content clearfix"
                    style={{ minHeight: "100px" }}
                  >
                    <section
                      id="steps-uid-2-p-0"
                      role="tabpanel"
                      aria-labelledby="steps-uid-2-h-0"
                      className="body current"
                      aria-hidden="false"
                    >
                      {isLoading ? (
                        <Audio />
                      ) : (
                        <>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="container-fluid">
                                <br />
                                <link
                                  href="icard-css/IDCard.css"
                                  rel="stylesheet"
                                />
                                <div className="id-card-tag"></div>
                                <div className="id-card-tag-strip"></div>
                                <div className="id-card-hook"></div>
                                <div className="id-card-holder">
                                  <div className="id-card">
                                    <div className="photo">
                                      <img
                                        id="ContentPlaceHolder1_imgimage"
                                        src={iCardData?.photo ?? ""}
                                      />
                                    </div>
                                    <h2 id="ContentPlaceHolder1_lblname">
                                      <b>{iCardData?.fullName ?? ""}</b>
                                    </h2>
                                    <br />
                                    <p id="ContentPlaceHolder1_lblUserid">
                                      <b>ID :</b> {iCardData?.username ?? ""}
                                    </p>
                                    <p id="ContentPlaceHolder1_lbrank">
                                      <b>Designation :</b>{" "}
                                      {iCardData?.rankname ?? ""}
                                    </p>

                                    <p id="ContentPlaceHolder1_lblmobileno">
                                      <b>Mobile No :</b>{" "}
                                      {iCardData?.mobile ?? ""}
                                    </p>
                                    <p id="ContentPlaceHolder1_lblemail">
                                      <b>Email :</b> {iCardData?.email ?? ""}
                                    </p>

                                    <p id="ContentPlaceHolder1_lbldob">
                                      <b>D.O BIRTH :</b>
                                      {moment(iCardData?.dob ?? "").format(
                                        "DD MMM YYYY"
                                      )}
                                    </p>
                                    <p id="ContentPlaceHolder1_lbldoj">
                                      <b>D.O JOINING :</b>{" "}
                                      {moment(
                                        iCardData?.dateofjoining ?? ""
                                      ).format("DD MMM YYYY")}
                                    </p>
                                    <hr />

                                    <p>
                                      <b>GMFX GLOBAL LIMITED.</b>
                                      <br />
                                      support@gmfxglobal.com
                                    </p>
                                    <p></p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </section>
                  </div>
                </form>
              </div>
            </>

            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
