import React from "react";
import { Route, HashRouter, Routes } from "react-router-dom";
import { Login } from "../component/Pages/Auth/login";
import { DashBoard } from "../component/Pages/Dashboard/dashboard";
import { PrivateRoute, PublicRoute } from "./protectedRoute";
import Error404 from "../component/Pages/NotFound/404Error";
import { Profile } from "../component/Pages/Master/profile";
import { ChangePassword } from "../component/Pages/Master/changePassword";
import { ChangePicture } from "../component/Pages/Master/changePicture";
import { Support } from "../component/Pages/Master/support";
import { SupportResponse } from "../component/Pages/Master/supportResponse";
import { InvestmentAggrement } from "../component/Pages/Master/investmentAggrement";
import { ICard } from "../component/Pages/Master/iCard";
import { Bank } from "../component/Pages/Kyc/bank";
import { Kyc } from "../component/Pages/Kyc/kyc";
import { InvestmentRequest } from "../component/Pages/Investment/investment_request";
import { ActionHistory } from "../component/Pages/Investment/action_history";
import { History } from "../component/Pages/Investment/history";
import { MainWalletSummary } from "../component/Pages/Main_Wallet/main-wallet-summary";
import { PayoutHistory } from "../component/Pages/Main_Wallet/payout-history";
import { TradingBonus } from "../component/Pages/Income_Wallet/trading-bonus";
import { LevelIncome } from "../component/Pages/Income_Wallet/level-income";
import { TradingProfitIncome } from "../component/Pages/Income_Wallet/trading-profit-income";
import { ClubIncome } from "../component/Pages/Income_Wallet/club-income";
import { RoyaltyIncome } from "../component/Pages/Income_Wallet/royalty-income";
import { RewardIncome } from "../component/Pages/Income_Wallet/reward-income";
import { AwardAndReward } from "../component/Pages/Income_Wallet/award-and-reward";
import { ClubReport } from "../component/Pages/Income_Wallet/club-report";
import { MyTeam } from "../component/Pages/My_Network/my-team";
import { MyDirect } from "../component/Pages/My_Network/my-direct";
import { MyTree } from "../component/Pages/My_Network/my-tree";
import { TeamBusiness } from "../component/Pages/My_Network/team-business";
import { RankStatus } from "../component/Pages/My_Network/rank-status";
import { Slider } from "../component/Pages/Front_Admin/slider";
import { SetService } from "../component/Pages/Front_Admin/set-service";
import { Updates } from "../component/Pages/Front_Admin/updates";
import { AboutUS } from "../component/Pages/Front_Admin/about-us";
import { Testinomial } from "../component/Pages/Front_Admin/testinomials";
import { ContactUS } from "../component/Pages/Front_Admin/contact-us";
import { Home } from "../component/Pages/Home/home";
import { AboutUsHome } from "../component/Pages/Home/aboutUs";
import { ContactUSHome } from "../component/Pages/Home/contact";
export const IndexRoute = () => {
  return (
    <HashRouter>
      <Routes>
        <Route exact path="/" element={<PublicRoute component={Home} />} />
        <Route
          exact
          path="/aboutus"
          element={<PublicRoute component={AboutUsHome} />}
        />
        <Route
          exact
          path="/contactus"
          element={<PublicRoute component={ContactUSHome} />}
        />
        <Route exact path="/login" element={<Login />} />
        <Route
          exact
          path="/dashboard"
          element={<PrivateRoute component={DashBoard} />}
        />
        <Route
          exact
          path="/profile"
          element={<PrivateRoute component={Profile} />}
        />
        <Route
          exact
          path="/change_password"
          element={<PrivateRoute component={ChangePassword} />}
        />
        <Route
          exact
          path="/change_profile_pic"
          element={<PrivateRoute component={ChangePicture} />}
        />
        <Route
          exact
          path="/support"
          element={<PrivateRoute component={Support} />}
        />
        <Route
          exact
          path="/support_response"
          element={<PrivateRoute component={SupportResponse} />}
        />
        <Route
          exact
          path="/investment_aggrement"
          element={<InvestmentAggrement />}
        />
        <Route
          exact
          path="/i_chard"
          element={<PrivateRoute component={ICard} />}
        />
        <Route exact path="/bank" element={<PrivateRoute component={Bank} />} />
        <Route exact path="/kyc" element={<PrivateRoute component={Kyc} />} />
        <Route
          exact
          path="/investment_request"
          element={<PrivateRoute component={InvestmentRequest} />}
        />
        <Route
          exact
          path="/action_history"
          element={<PrivateRoute component={ActionHistory} />}
        />
        <Route
          exact
          path="/history"
          element={<PrivateRoute component={History} />}
        />
        <Route
          exact
          path="/main_wallet_summary"
          element={<PrivateRoute component={MainWalletSummary} />}
        />
        <Route
          exact
          path="/payout_hisotry"
          element={<PrivateRoute component={PayoutHistory} />}
        />
        <Route
          exact
          path="/trading_bonus"
          element={<PrivateRoute component={TradingBonus} />}
        />
        <Route
          exact
          path="/trading_bonus"
          element={<PrivateRoute component={TradingBonus} />}
        />
        <Route
          exact
          path="/level_income"
          element={<PrivateRoute component={LevelIncome} />}
        />
        <Route
          exact
          path="/trading_profit_income"
          element={<PrivateRoute component={TradingProfitIncome} />}
        />
        <Route
          exact
          path="/club_income"
          element={<PrivateRoute component={ClubIncome} />}
        />
        <Route
          exact
          path="/royalty_income"
          element={<PrivateRoute component={RoyaltyIncome} />}
        />
        <Route
          exact
          path="/reward_income"
          element={<PrivateRoute component={RewardIncome} />}
        />
        <Route
          exact
          path="/award_rewards"
          element={<PrivateRoute component={AwardAndReward} />}
        />
        <Route
          exact
          path="/club_report"
          element={<PrivateRoute component={ClubReport} />}
        />
        <Route
          exact
          path="/my_team"
          element={<PrivateRoute component={MyTeam} />}
        />
        <Route
          exact
          path="/my_direct"
          element={<PrivateRoute component={MyDirect} />}
        />
        <Route
          exact
          path="/my_tree"
          element={<PrivateRoute component={MyTree} />}
        />
        <Route
          exact
          path="/team_business"
          element={<PrivateRoute component={TeamBusiness} />}
        />
        <Route
          exact
          path="/rank_status"
          element={<PrivateRoute component={RankStatus} />}
        />
        <Route
          exact
          path="/front-admin-slider"
          element={<PrivateRoute component={Slider} />}
        />
        <Route
          exact
          path="/front-admin-service"
          element={<PrivateRoute component={SetService} />}
        />
        <Route
          exact
          path="/front-admin-updates"
          element={<PrivateRoute component={Updates} />}
        />
        <Route
          exact
          path="/front-admin-about-us"
          element={<PrivateRoute component={AboutUS} />}
        />
        <Route
          exact
          path="/front-admin-testinomial"
          element={<PrivateRoute component={Testinomial} />}
        />
        <Route
          exact
          path="/front-admin-contact-us-feedback"
          element={<PrivateRoute component={ContactUS} />}
        />

        {/* for routes which is not present in the application */}
        <Route path="/*" element={<Error404 />} />
      </Routes>
    </HashRouter>
  );
};
