export const Input = ({
  label,
  type,
  name,
  required,
  style = {},
  disabled = false,
  value = null,
  helperText = "",
  helperStyle = {},
  onHandleChange,
}) => {
  return (
    <div className="form-group">
      <label for="wfirstName2"> {label} :</label>
      {required ? <span className="danger"> *</span> : null}
      <input
        type={type}
        className="form-control required"
        name={name}
        style={style}
        disabled={disabled}
        value={value}
        onChange={(e) => onHandleChange(e)}
      />{" "}
      {!!helperText ? <span style={helperStyle}>{helperText}</span> : null}
    </div>
  );
};
