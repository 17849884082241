import React from "react";
import GMXLogo from "../../assets/images/gmfx-logo.png";
import GMXLogoDark from "../../assets/images/logo-dark.png";
import { Link } from "react-router-dom";

export const HomeFooter = () => {
  return (
    <>
      {/* <!-- footer --> */}
      <footer className="bg-black">
        <div className="container p-footer">
          <div className="row justify-content-between">
            {/* <!-- footer content --> */}
            <div className="col-lg-5 mb-5 mb-lg-0">
              {/* <!-- logo --> */}
              <a className="mb-4 f-logo d-inline-block" href="index-2.html">
                <img src={GMXLogoDark} alt="" style={{ width: "100px" }} />
              </a>
              <p className="text-light">
                100% Legal & Genuine concept. Orginal Revenue Generation Plan.
                Automiatic payment transfer to the customer's account at the
                time of payment. Member will also get agreement with GST
                receipt. 100% Risk free business opportunity.
              </p>
              {/* <!-- social links --> */}
              <ul className="list-inline social-icon-fotter">
                <li>
                  <a href="https://www.facebook.com/gmfxglobal1limited/">
                    <i className="fa fa-fw fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/gmfxglobalofcial/">
                    <i className="fa fa-fw fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/@GMFXGLOBAL1LIMITED">
                    <i className="fa fa-fw fa-youtube"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="row">
                <div className="col-6">
                  <h4 className="text-white mb-4">Quick Links</h4>
                  <ul className="list-styled">
                    <li className="mb-lg-3 mb-2 text-light">
                      <Link className="text-light d-block" to="/">
                        Home
                      </Link>
                    </li>
                    <li className="mb-lg-3 mb-2 text-light">
                      <Link className="text-light d-block" to="/aboutus">
                        About us
                      </Link>
                    </li>
                    {/* <li className="mb-lg-3 mb-2 text-light">
                      <a className="text-light d-block" href="blog.html">
                        Blog
                      </a>
                    </li>
                    <li className="mb-lg-3 mb-2 text-light">
                      <a className="text-light d-block" href="team.html">
                        Team
                      </a>
                    </li> */}
                    <li className="mb-lg-3 mb-2 text-light">
                      <Link className="text-light d-block" to="/contactus">
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="col-6">
                  <h4 className="text-white mb-4">Contact Info</h4>
                  <ul className="list-unstyled">
                    <li className="text-light mb-3">
                      <address>
                        SATYALOK COLONEY, PHASE -6 NEAR RANVEER GARDEN HALDWANI,
                        UTTARAKHAND, NAINITAL, PIN -263139
                      </address>
                    </li>
                    <li className="text-light mb-3">
                      <a href="tel:+919258044511">
                        +91 9258044511, +91 7535039811
                      </a>{" "}
                    </li>
                    <li className="text-light mb-3">
                      <a href="mailto:info@test.com">info@gmfxglobal.group</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- copyright --> */}
        <div className="container copyright-c">
          <div className="row">
            <div className="col-lg-6 col-md-12 text-center text-center text-lg-left mb-3 mb-md-0">
              <p className="mb-0 text-white">
                © Copyright <span id="year"></span>
                {/* Softechbee */} <span className="color">GMFX Global</span>.
              </p>
            </div>
            <div className="col-lg-6 col-md-12 text-center text-lg-right">
              <ul className="list-inline">
                <li className="list-inline-item mx-0">
                  <a
                    className="d-inline-block px-3 text-white border-right"
                    href="terms-conditions.html"
                  >
                    Terms of Service
                  </a>
                </li>
                <li className="list-inline-item mx-0">
                  <a
                    className="d-inline-block px-3 text-white"
                    href="privacy-policy.html"
                  >
                    Privacy Policy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
      {/* <!-- footer --> */}
    </>
  );
};
