import React, { useState, useEffect } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Table } from "../../small-components/table";
import { Input } from "../../small-components/inputFiled";
import { clubReportTable } from "../../../utils/constant";
import service from "../../../service";
import moment from "moment";
import toastr from "toastr";
import { Audio } from "react-loader-spinner";
import { dateFormatter, getUserInfo } from "../../../utils/common";
export const ClubReport = () => {
  const [myData, setMyData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getData(offset, limit);
  }, []);
  const getData = async (offset, limit) => {
    setIsLoading(true);
    const res = await service.requestApi.clubReport(offset, limit, null, null);
    if ("data" in res) {
      if (res?.data?.length > 0) {
        const finalArray = [];
        res?.data?.map((rs, idx) => {
          const Obj = {
            id: idx + 1,
            username: JSON.parse(getUserInfo()).userName ?? "er",
            doa: dateFormatter(rs?.doa ?? ""),
            remark: "GOLD CLUB",
            reward: "Rs.10,000/- per Month",
            totalmonths: 1,
            paidstatus: "Continue",
          };
          finalArray.push(Obj);
        });
        setMyData(finalArray);
      } else {
        setMyData(res?.data);
      }
      setTotalCount(res?.totalRecord);
      setIsLoading(false);
    } else {
      toastr.error("No data found or Error Occuried");
      setIsLoading(false);
    }
  };
  const handlePageChange = (PageNumber) => {
    getData(PageNumber, limit, null, null);
    setOffset(PageNumber);
    setActivePage(PageNumber);
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Income Wallet"} child={"Club Report"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Club Report</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div
                  className="content clearfix"
                  style={{ minHeight: "100px" }}
                >
                  <section className="content">
                    <div className="row">
                      <div class="box">
                        <div class="box-header with-border">
                          {/* <h4 class="box-title">Support Response Table</h4> */}
                        </div>
                        {/* <!-- /.box-header --> */}
                        {isLoading ? (
                          <Audio
                            height="80"
                            width="80"
                            radius="9"
                            color="green"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                          />
                        ) : (
                          <Table
                            tHeader={clubReportTable}
                            tData={myData}
                            calledFrom="club_report"
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                            limit={limit}
                            pageRangeDisplayed={2}
                            activePage={activePage}
                            item={myData?.length ?? 0}
                          />
                        )}
                        {/* <!-- /.box-body --> */}
                      </div>
                    </div>
                  </section>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
