import React from "react";
import { Header } from "./header";
import { Siderbar } from "./sidebar";
import { Footer } from "./footer";

export const FinalLayout = (props) => {
  return (
    <div className="wrapper">
      <Header />
      <Siderbar />
      {props.children}
      <Footer />
    </div>
  );
};
