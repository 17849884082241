// store.js
import { combineReducers } from "redux";
import { pathReducer } from "./path-reducer";
import { sidebarToggleReducer } from "./side-toggle-reducer";
// Combine reducers
export const rootReducer = combineReducers({
  sidebarPath: pathReducer,
  sidebarToggle: sidebarToggleReducer,
  // Add more reducers here if needed
});
