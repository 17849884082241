import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useBodyClass from "../../../hooks/customBodyHook";
import BGImage from "../../../images/auth-bg/bg.jpg";
import toastr from "toastr";
import service from "../../../service";
import { LoaderSpinner } from "../../../utils/common";
import { useDispatch } from "react-redux";
export const Login = () => {
  const navigate = useNavigate();
  const [isTreeActive, setIsTreeActive] = useState(window.innerWidth < 766);
  const [credentail, setCredential] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      const compWidth = window.innerWidth;
      if (compWidth < 766) {
        setIsTreeActive(true);
      } else {
        setIsTreeActive(false);
      }
    };

    // Set initial state based on current window width
    handleResize();

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); // Empty dependency array means this effect runs only on mount and unmount
  useBodyClass("hold-transition theme-classic");

  const onHandleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    const clone = JSON.parse(JSON.stringify(credentail));
    clone[name] = value;
    setCredential(clone);
  };

  const login = async () => {
    if (!!credentail?.username && !!credentail.password) {
      setIsLoading(true);
      const res = await service.requestApi.loginReq(
        credentail?.username,
        credentail.password
      );
      if (!!res?.token) {
        dispatch({
          type: "is_toggle",
          toggle: false,
        });
        setIsLoading(false);
        localStorage.setItem("isSideCol", "false");
        toastr.success("Login Successfully", "Success");
        localStorage.setItem("token", res?.token);
        localStorage.setItem("user", JSON.stringify(res?.user));
        navigate("/dashboard");
      } else {
        toastr.error("Wrong Credential", "Error");
        localStorage.clear();
        setIsLoading(false);
      }
    } else {
      toastr.error("username and password are required field", "Error", {
        preventDuplicates: true,
      });
    }
  };

  return (
    <div
      class="auth-2-outer row align-items-center h-p100 m-0 bg-img"
      style={{
        backgroundImage: `url(${BGImage})`,
        minHeight: "100vh",
      }}
    >
      <div
        class="auth-2 bg-gradient-classic"
        style={!isTreeActive ? { height: "100vh" } : { height: "auto" }}
      >
        <div class="auth-logo font-size-30">
          <a href="javascript:voi(0);" class="text-white">
            <b>GMFX</b>Admin
          </a>
        </div>
        {/* <!-- /.login-logo --> */}
        <div class="auth-body">
          <p class="auth-msg text-white-50">Sign in to start your session</p>

          <form method="post" class="form-element">
            <div class="form-group has-feedback">
              <input
                type="test"
                class="form-control text-white plc-white"
                placeholder="Username"
                name="username"
                value={credentail?.username ?? ""}
                onChange={(e) => onHandleChange(e)}
              />
              <span class="ion ion-email form-control-feedback text-white"></span>
            </div>
            <div class="form-group has-feedback">
              <input
                type="password"
                class="form-control text-white plc-white"
                placeholder="Password"
                name="password"
                value={credentail?.password ?? ""}
                onChange={(e) => onHandleChange(e)}
              />
              <span class="ion ion-locked form-control-feedback text-white"></span>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="checkbox">
                  <input type="checkbox" id="basic_checkbox_1" />
                  <label for="basic_checkbox_1" class="text-white">
                    Remember Me
                  </label>
                </div>
              </div>
              {/* <!-- /.col --> */}
              <div class="col-6">
                <div class="fog-pwd">
                  <a href="javascript:void(0)" class="text-white">
                    <i class="ion ion-locked"></i> Forgot pwd?
                  </a>
                  <br />
                </div>
              </div>
              {/* <!-- /.col --> */}
              <div class="col-12 text-center">
                <button
                  type="button"
                  class="btn btn-rounded mt-10 btn-success"
                  onClick={() => login()}
                >
                  {isLoading ? <LoaderSpinner /> : "SIGN IN"}
                </button>
              </div>
              {/* <!-- /.col --> */}
            </div>
          </form>

          <div class="text-center text-dark">
            <p class="mt-50 text-white">- Sign With -</p>
            <p class="gap-items-2 mb-20">
              <a
                class="btn btn-social-icon btn-round btn-outline btn-white"
                href="#"
              >
                <i class="fa fa-facebook"></i>
              </a>
              <a
                class="btn btn-social-icon btn-round btn-outline btn-white"
                href="#"
              >
                <i class="fa fa-twitter"></i>
              </a>
              <a
                class="btn btn-social-icon btn-round btn-outline btn-white"
                href="#"
              >
                <i class="fa fa-google-plus"></i>
              </a>
              <a
                class="btn btn-social-icon btn-round btn-outline btn-white"
                href="#"
              >
                <i class="fa fa-instagram"></i>
              </a>
            </p>
          </div>
          {/* <!-- /.social-auth-links --> */}

          <div class="margin-top-30 text-center text-white">
            <p>
              Don't have an account?{" "}
              <a href="auth_register2.html" class="text-info m-l-5">
                Sign Up
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
