import React, { useState } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { previewFile } from "../../../utils/common";
import { InputFile } from "../../small-components/inputFile";
import { LoaderSpinner } from "../../../utils/common";
import { Link } from "react-router-dom";
import service from "../../../service";
import toastr from "toastr";
import { cancelBtn } from "./master-helper/helper";
export const ChangePicture = () => {
  const [profileImg, setProfileImg] = useState(null);
  const [loader, setLoader] = useState(false);
  const onHandleChange = (e) => {
    const file = e?.target?.files[0];
    console.log("e ===>>", e, file);
    setProfileImg(file);
    previewFile("img_preview-profile-change", "file-input-profile-change");
  };
  const onSubmit = async () => {
    if (profileImg) {
      const formData = new FormData();
      formData.append("file", profileImg);
      const _res = await service.requestApi.changeProfileImage(formData);
      console.log("_res ===>>>", _res);
    } else {
      toastr.error("Please Upload Image", "Error");
    }
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Master"} child={"Change Profile Picture"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Profile Picture</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div
                  className="content clearfix"
                  style={{ minHeight: "100px" }}
                >
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <InputFile
                          previewFile={onHandleChange}
                          inputID="file-input-profile-change"
                          labelFor="file-input-profile-change"
                          label="Change Profile Picture"
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <div
                          className="custom_img_preview"
                          id="img_preview-profile-change"
                        ></div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link to="/dashboard" role="menuitem" style={cancelBtn}>
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li
                      aria-hidden="true"
                      style={{ display: "none;" }}
                      onClick={() => onSubmit()}
                    >
                      <a href="javascript:void(0)" role="menuitem">
                        {loader ? <LoaderSpinner /> : "Save"}
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
