import React from "react";

export const Footer = () => {
  return (
    // <!-- /.content-wrapper -->
    <footer className="main-footer" style={{ zIndex: "8" }}>
      <div className="pull-right d-none d-sm-inline-block">
        <ul className="nav nav-primary nav-dotted nav-dot-separated justify-content-center justify-content-md-end">
          <li className="nav-item">
            <a className="nav-link" href="javascript:void(0)">
              FAQ
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Purchase Now
            </a>
          </li>
        </ul>
      </div>
      &copy; 2020 <a href="javascript:void(0)">GMFX Global</a>. All Rights
      Reserved.
    </footer>
  );
};
