import React from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Link } from "react-router-dom";
import { Input } from "../../small-components/inputFiled";
import { uploadMsg } from "../../../utils/constant";
import { InputFile } from "../../small-components/inputFile";
import { previewFile } from "../../../utils/common";

export const Kyc = () => {
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"KYC / BANK"} child={"KYC"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">KYC</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Pan Status"}
                          name={"panStatus"}
                          required={false}
                          type={"text"}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Aadhar Status"}
                          name={"aadharStatus"}
                          required={false}
                          type={"text"}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"KYC Status"}
                          name={"kycStatus"}
                          required={false}
                          type={"text"}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Admin Remark"}
                          name={"adminRemark"}
                          required={false}
                          type={"text"}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Pan Number"}
                          name={"panNumber"}
                          required={false}
                          type={"number"}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Aadhar Number"}
                          name={"aadharNumber"}
                          required={false}
                          type={"number"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <InputFile
                              previewFile={() =>
                                previewFile("img_preview-pan", "file-input-pan")
                              }
                              inputID="file-input-pan"
                              labelFor="file-input-pan"
                              label="Upload Pan"
                              helperStyle={{ color: "red" }}
                              helperText={uploadMsg}
                            />
                          </div>
                          <div className="col-md-6">
                            <div
                              style={{ marginTop: "5px" }}
                              className="custom_img_preview"
                              id="img_preview-pan"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <InputFile
                              previewFile={() =>
                                previewFile(
                                  "img_preview-adhaar",
                                  "file-input-adhaar"
                                )
                              }
                              inputID="file-input-adhaar"
                              labelFor="file-input-adhaar"
                              label="Upload Adhaar"
                              helperStyle={{ color: "red" }}
                              helperText={uploadMsg}
                            />
                          </div>
                          <div className="col-md-6">
                            <div
                              style={{ marginTop: "5px" }}
                              className="custom_img_preview"
                              id="img_preview-adhaar"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <InputFile
                              previewFile={() =>
                                previewFile(
                                  "img_preview-adhaar-back-side",
                                  "file-input-adhaar-back-side"
                                )
                              }
                              inputID="file-input-adhaar-back-side"
                              labelFor="file-input-adhaar-back-side"
                              label="Upload Adhaar Back Side"
                              helperStyle={{ color: "red" }}
                              helperText={uploadMsg}
                            />
                          </div>
                          <div className="col-md-6">
                            <div
                              style={{ marginTop: "5px" }}
                              className="custom_img_preview"
                              id="img_preview-adhaar-back-side"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link
                        to="/dashboard"
                        role="menuitem"
                        style={{ color: "#999999", backgroundColor: "#ffffff" }}
                      >
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li aria-hidden="true" style={{ display: "none;" }}>
                      <a href="#finish" role="menuitem">
                        Save
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
