import React from "react";
import Pagination from "react-js-pagination";
import { ENV } from "../../config";
import { openOnNewTab } from "../../utils/common";
export const Table = ({
  tHeader,
  tData,
  calledFrom = "",
  item = 10,
  totalCount = 0,
  activePage = 1,
  limit = 10,
  pageRangeDisplayed = 5,
  handlePageChange,
}) => {
  // Ensure tData is always an array of objects
  const dataTable = Array.isArray(tData) ? tData : [];

  const cookedData = (td, key) => {
    if (
      (key === "receipt" && calledFrom === "action_request") ||
      key === "clientremark"
    ) {
      if (key === "clientremark") {
        return (
          <i
            class="fa fa-eye"
            aria-hidden="true"
            style={{ cursor: "pointer" }}
            onClick={() => openOnNewTab(`${ENV.WEB_URL_IMG}${td["receipt"]}`)}
          ></i>
        );
      } else {
        return (
          <img src={`${ENV.WEB_URL_IMG}${td[key]}`} style={{ width: "20px" }} />
        );
      }
    } else if (
      calledFrom === "award_and_reward" &&
      (key === "paidstatus" || key === "status")
    ) {
      return (
        <span
          className={
            td[key] === "Pending" || td[key] === true
              ? "badge badge-danger badge-lg"
              : "badge badge-success badge-lg"
          }
        >
          {key === "paidstatus"
            ? td[key]
            : td[key] === true
            ? "Completed"
            : "Re Top Up Now ?"}
        </span>
      );
    } else if (calledFrom === "rank_status" && key === "status") {
      return (
        <span
          className={
            td[key] === "Achieved"
              ? "badge badge-success badge-lg"
              : "badge badge-danger badge-lg"
          }
        >
          {td[key]}
        </span>
      );
    } else {
      return td[key] ?? "er";
    }
  };
  return (
    <div class="box no-padding">
      <div class="table-responsive">
        <div className="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
          <table class="table table-hover">
            <tbody>
              <tr>
                {tHeader.length > 0 ? (
                  tHeader.map((th, idx) => (
                    <th key={idx}>{th?.header ?? "er"}</th>
                  ))
                ) : (
                  <th>No Header</th>
                )}
              </tr>
              {dataTable.length > 0 ? (
                dataTable.map((td, idx) => (
                  <tr key={idx}>
                    {Object.keys(td).map((key) => (
                      <td key={key}>
                        {
                          // key === "id" ? (
                          //   <a href="javascript:void(0)">{td[key] ?? "er"}</a>
                          // ) : key === "subject" ? (
                          //   <span className="text-muted">
                          //     <i className="fa fa-clock-o"></i>
                          //     {td[key]}
                          //   </span>
                          // ) : key === "response" ? (
                          //   <span className="badge badge-pill badge-danger">
                          //     {td[key] ?? "er"}
                          //   </span>
                          // ) : (
                          // )
                          cookedData(td, key)
                        }
                      </td>
                    ))}
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={tHeader?.length ?? 0}
                    style={{ textAlign: "center" }}
                  >
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          <div className="row">
            <div className="col-md-4">
              <div
                class="dataTables_info"
                id="example_info"
                role="status"
                aria-live="polite"
              >
                Total {totalCount} entries
              </div>
            </div>
            {totalCount > 0 && (
              <div className="col-md-8">
                <div
                  class="dataTables_paginate paging_simple_numbers"
                  id="example_paginate"
                >
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={limit}
                    totalItemsCount={totalCount}
                    pageRangeDisplayed={pageRangeDisplayed}
                    onChange={handlePageChange}
                    activeClass="paginate_button page-item active"
                    itemClass="paginate_button page-item"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
