import React, { useState, useEffect } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Table } from "../../small-components/table";
import { Input } from "../../small-components/inputFiled";
import { tradingProfiltIncomeTable } from "../../../utils/constant";
import service from "../../../service";
import moment from "moment";
import toastr from "toastr";
import { Audio } from "react-loader-spinner";
import { getUserInfo, dateFormatter } from "../../../utils/common";
export const TradingProfitIncome = () => {
  const getInfo = JSON.parse(getUserInfo());
  const [myData, setMyData] = useState([]);
  const [startData, setStartDate] = useState(
    null
    // moment().startOf("day").format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = useState(
    null
    // moment().add(1, "days").startOf("day").format("YYYY/MM/DD")
  );
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getData(offset, limit, startData, endDate);
  }, []);
  const getData = async (offset, limit, startData, endDate) => {
    setIsLoading(true);
    const res = await service.requestApi.myIncomeWallet(
      offset,
      limit,
      startData,
      endDate,
      "Direct"
    );
    if ("data" in res) {
      if (res?.data?.length > 0) {
        const finalArray = [];
        res?.data?.map((rs, idx) => {
          const Obj = {
            id: idx + 1,
            username: rs?.username ?? getInfo?.userName ?? "er",
            date: moment(rs?.data).format("DD/MM/YYYY"),
            particular: rs?.remark ?? "er",
            credit: rs?.credit ?? "er",
            // debit: rs?.debit ?? "er",
          };
          finalArray.push(Obj);
        });

        setMyData(finalArray);
      } else {
        setMyData(res?.data);
      }
      setIsLoading(false);
      setTotalCount(res?.totalRecord);
    } else {
      toastr.error("No data found or Error Occuried");
      setIsLoading(false);
    }
  };
  const handlePageChange = (PageNumber) => {
    getData(PageNumber, limit, startData, endDate);
    setOffset(PageNumber);
    setActivePage(PageNumber);
  };
  const onHandleChange = (e) => {
    const value =
      e?.target?.value !== "" ? dateFormatter(e?.target?.value) : null;
    const name = e?.target?.name ?? "";
    if (name === "startDate") {
      setStartDate(value);
    } else {
      setEndDate(value);
    }
  };
  const onSearch = () => {
    if ((!!startData && endDate) || (startData === null && endDate === null)) {
      getData(offset, limit, startData, endDate);
    } else {
      toastr.error("Please select the start and end date", "Error");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Income Wallet"} child={"Trading Profit Income"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Trading Profit Income</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div
                  className="content clearfix"
                  style={{ minHeight: "100px" }}
                >
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-4">
                        <Input
                          label={"Start Date"}
                          name={"startDate"}
                          required={true}
                          type={"date"}
                          onHandleChange={onHandleChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <Input
                          label={"End Date"}
                          name={"endDate"}
                          required={true}
                          type={"date"}
                          onHandleChange={onHandleChange}
                        />
                      </div>
                      <div className="col-md-4">
                        <div className="actions clearfix">
                          <button
                            type="button"
                            class="btn btn-success mt-4"
                            onClick={() => onSearch()}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="content">
                    <div className="row">
                      <div class="box">
                        <div class="box-header with-border">
                          {/* <h4 class="box-title">Support Response Table</h4> */}
                        </div>
                        {/* <!-- /.box-header --> */}
                        {isLoading ? (
                          <Audio
                            height="80"
                            width="80"
                            radius="9"
                            color="green"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                          />
                        ) : (
                          <Table
                            tHeader={tradingProfiltIncomeTable}
                            tData={myData}
                            calledFrom="level_income"
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                            limit={limit}
                            pageRangeDisplayed={2}
                            activePage={activePage}
                            item={myData?.length ?? 0}
                          />
                        )}
                        {/* <!-- /.box-body --> */}
                      </div>
                    </div>
                  </section>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
