import logo from "./logo.svg";
import "./App.css";
import { IndexRoute } from "./routes/routes";
// import toastr from "toastr";
function App(props) {
  return (
    <>
      {/* <toastr /> */}
      <IndexRoute />
    </>
  );
}

export default App;
