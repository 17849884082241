import axios from "axios";
import { ENV } from "./config";

async function getData(url) {
  try {
    let _r = await axios.get(url, {
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
      },
    });
    return _r.data;
  } catch (res) {
    return res;
  }
}
async function postData(url, body) {
  try {
    let _r = await axios.post(url, body, {
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
      },
    });
    return _r.data;
  } catch (res) {
    return res;
  }
}

async function deleteData(url) {
  try {
    let _r = await axios.delete(url, {
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
      },
    });
    return _r.data;
  } catch (e) {}
}

async function putData(url, body) {
  try {
    let _r = await axios.put(url, body, {
      headers: {
        Authorization: "Bearer ".concat(localStorage.getItem("token")),
      },
    });
    return _r.data;
  } catch (e) {}
}
// Axios request handlers

// axios.interceptors.request.use(
//   (config) => {
//     if (window.localStorage.getItem("authToken")) {
//       config.headers.Authorization =
//         "Bearer " + window.localStorage.getItem("authToken");
//     }
//     return config;
//   },
//   (error) =>
//     Promise.reject((error) => {
//       notify.show(error);
//     })
// );

// Axios response handlers

// axios.interceptors.response.use(
//   function (response) {
//     if (response.data.message === "invalid token") {
//       window.localStorage.removeItem("authToken");
//       window.location.href = "/login";
//     } else if (response.data.message === "jwt expired") {
//       window.localStorage.removeItem("authToken");
//       window.localStorage.removeItem("profile");
//       window.localStorage.removeItem("type2");
//       window.localStorage.removeItem("type3");
//       window.location.href = "/";
//     } else if (response.data.status == false) {
//       // notify.show(response.data.message, "error", 5000)
//       // window.localStorage.removeItem('authToken')
//       // window.location.href = '/login'
//     }

//     return response;
//   },
//   function (error) {
//     console.log(error);
//     // notify.show(error)
//     return Promise.reject(error);
//   }
// );

const requestApi = {
  loginReq: async (username, password) => {
    const url =
      ENV.WEB_URL +
      `/api/Authentication/token?username=${username}&password=${password}`;
    let r = await postData(url);
    return r;
  },
  changePassword: async (oldPassword, newPassword) => {
    const url =
      ENV.WEB_URL +
      `/api/User/ChangePassword?OldPassword=${oldPassword}&NewPassword=${newPassword}`;
    let r = await postData(url);
    return r;
  },
  profile: async (body) => {
    const url =
      ENV.WEB_URL +
      `/api/User/Profile?Name=${body?.name ?? ""}&fatherName=${
        body?.fname ?? ""
      }&address=${body?.address ?? ""}&dateofbirth=${body?.dob ?? ""}&Mobile=${
        body?.mobile ?? ""
      }&Email=${body?.email ?? ""}&pincode=${body?.pin ?? ""}&nomineename=${
        body?.nomineename ?? ""
      }&nage=${body?.nage ?? ""}&nomineemobile=${
        body?.nomineemobile ?? ""
      }&relation=${body?.relation ?? ""}`;
    let r = await postData(url);
    return r;
  },
  supportResponse: async (offset, limit) => {
    const url =
      ENV.WEB_URL + `/SupportResponse?PageNumber=${offset}&PageSize=${limit}`;
    let r = await getData(url);
    return r;
  },
  support: async (subject, msg) => {
    const url =
      ENV.WEB_URL + `/api/User/SupportTicket?Subject=${subject}&Message=${msg}`;
    let r = await postData(url);
    return r;
  },
  changeProfileImage: async (body) => {
    const url = ENV.WEB_URL + `/api/User/UploadProfileImg`;
    let r = await postData(url, body);
    return r;
  },
  bankDetails: async (body, fileImg) => {
    const url =
      ENV.WEB_URL +
      `/api/User/Bank?AccountNo=${body?.accno ?? ""}&BankName=${
        body?.bankname ?? ""
      }&IFSC=${body?.ifsc ?? ""}&BranchName=${
        body?.branchname ?? ""
      }&HolderName=${body?.holdername ?? ""}&AccountType=${
        body?.acctype ?? ""
      }&AccountImg=${body?.accImg}`;
    let r = await postData(url, fileImg);
    return r;
  },
  myDirectTeam: async (offset, limit, startDate, endDate) => {
    const url =
      ENV.WEB_URL +
      `/DirectTeam?PageNumber=${offset}&PageSize=${limit}&FromDate=${startDate}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  myTeam: async (offset, limit, startDate, endDate, side) => {
    const url =
      ENV.WEB_URL +
      `/MyTeam?PageNumber=${offset}&PageSize=${limit}&Side=${side}&FromDate=${startDate}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  myIncomeWallet: async (offset, limit, startDate, endDate, type) => {
    const url =
      ENV.WEB_URL +
      `/IncomeReport?PageNumber=${offset}&PageSize=${limit}&Keyword=${type}&FromDate=${startDate}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  awardAndReward: async (offset, limit, startDate, endDate) => {
    const url =
      ENV.WEB_URL +
      `/RewardReport?PageNumber=${offset}&PageSize=${limit}&FromDate=${startDate}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  iCard: async () => {
    const url = ENV.WEB_URL + `/ICard`;
    let r = await getData(url);
    return r;
  },
  myIncomeTradingBonus: async (offset, limit, startDate, endDate) => {
    const url =
      ENV.WEB_URL +
      `/IncomeROI?PageNumber=${offset}&PageSize=${limit}&FromDate=${startDate}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  actionHistory: async (offset, limit) => {
    const url =
      ENV.WEB_URL + `/ActionHistory?PageNumber=${offset}&PageSize=${limit}`;
    let r = await getData(url);
    return r;
  },
  investmentHistory: async (offset, limit) => {
    const url =
      ENV.WEB_URL + `/InvestmentHistory?PageNumber=${offset}&PageSize=${limit}`;
    let r = await getData(url);
    return r;
  },
  clubReport: async (offset, limit, startData, endDate) => {
    const url =
      ENV.WEB_URL +
      `/ClubReport?PageNumber=${offset}&PageSize=${limit}&FromDate=${startData}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  mainWalletSummary: async (offset, limit, startData, endDate) => {
    const url =
      ENV.WEB_URL +
      `/MainWalletSummary?PageNumber=${offset}&PageSize=${limit}&FromDate=${startData}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  getProfileData: async () => {
    const url = ENV.WEB_URL + `/GetProfile`;
    let r = await getData(url);
    return r;
  },
  getAgrement: async () => {
    const url = ENV.WEB_URL + `/Agreement`;
    let r = await getData(url);
    return r;
  },
  getTeamBusiness: async (offset, limit, startData, endDate) => {
    const url =
      ENV.WEB_URL +
      `/TeamBussiness?PageNumber=${offset}&PageSize=${limit}&FromDate=${startData}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  getRankStatus: async (offset, limit) => {
    const url =
      ENV.WEB_URL + `/RankStatus?PageNumber=${offset}&PageSize=${limit}`;
    let r = await getData(url);
    return r;
  },
  getBankDetail: async () => {
    const url = ENV.WEB_URL + `/BankDetails`;
    let r = await getData(url);
    return r;
  },
  getPayoutHistory: async (offset, limit, startData = null, endDate = null) => {
    const url =
      ENV.WEB_URL +
      `/GetPayout?PageNumber=${offset}&PageSize=${limit}&FromDate=${startData}&Todate=${endDate}`;
    let r = await getData(url);
    return r;
  },
  getDashboard: async () => {
    const url = ENV.WEB_URL + `/Dashboard`;
    let r = await getData(url);
    return r;
  },
  getNews: async () => {
    const url = ENV.WEB_URL + `/News`;
    let r = await getData(url);
    return r;
  },
};

export default {
  requestApi,
};
