import moment from "moment";
export const previewFile = (preID = "img_preview", inputID = "file-input") => {
  const input = document.getElementById(inputID);
  const preview = document.getElementById(preID);
  const file = input.files[0];
  const reader = new FileReader();

  reader.onloadend = function () {
    preview.innerHTML =
      '<img src="' +
      reader.result +
      '" alt="Selected Image" style="max-width: 100%; height: auto;"/>';
  };

  if (file) {
    reader.readAsDataURL(file);
  } else {
    preview.innerHTML = "";
  }
};
export const getUserInfo = () => {
  return localStorage.getItem("user");
};
export const dateFormatter = (data) => {
  return moment(data).format("YYYY-MM-DD");
};
export const LoaderSpinner = () => {
  return (
    <div class="spinner-border text-success" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  );
};
export const openOnNewTab = (url) => {
  window.open(url);
};
const passwordRegex =
  /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{10,}$/;

export const testPassword = (password) => passwordRegex.test(password);

export const generateColor = () => {
  const randomColor = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  return randomColor;
};
