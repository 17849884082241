import React from "react";
import { Link } from "react-router-dom";
import useBodyClass from "../../../hooks/customBodyHook";

export const Error404 = () => {
  useBodyClass("hold-transition theme-classic");
  return (
    <div style={{ height: "100vh" }}>
      <section className="error-page h-p100 bg-gradient-classic">
        <div className="container h-p100">
          <div className="row h-p100 align-items-center justify-content-center text-center">
            <div className="col-lg-7 col-md-10 col-12">
              <div className="b-double border-white rounded30">
                <h1 className="text-white font-size-180 font-weight-bold error-page-title">
                  {" "}
                  404
                </h1>
                <h1 className="text-white">Page Not Found !</h1>
                <h3 className="text-white">looks like, page doesn't exist</h3>
                <div className="my-30">
                  <Link to="/" className="btn btn-danger btn-rounded">
                    Back to dashboard
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Error404;
