import React from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Table } from "../../small-components/table";
import { Input } from "../../small-components/inputFiled";
import { updatesTable } from "../../../utils/constant";
import { Link } from "react-router-dom";

export const Updates = () => {
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Front Admin Slider"} child={"Updates"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Updates</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div
                  className="content clearfix"
                  style={{ minHeight: "100px" }}
                >
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Running Days"}
                          name={"runningDays"}
                          required={true}
                          type={"text"}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Total Accounts"}
                          name={"totalAcc"}
                          required={true}
                          type={"text"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Visitors"}
                          name={"visitor"}
                          required={true}
                          type={"text"}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Last Days"}
                          name={"lastDays"}
                          required={true}
                          type={"text"}
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link
                        to="/dashboard"
                        role="menuitem"
                        style={{ color: "#999999", backgroundColor: "#ffffff" }}
                      >
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li aria-hidden="true" style={{ display: "none;" }}>
                      <a href="#finish" role="menuitem">
                        Save
                      </a>
                    </li>
                  </ul>
                </div>
                <section className="content">
                  <div className="row">
                    <div className="box">
                      <div className="box-header with-border">
                        {/* <h4 className="box-title">Support Response Table</h4> */}
                      </div>
                      {/* <!-- /.box-header --> */}
                      <Table
                        tHeader={updatesTable}
                        tData={[]}
                        calledFrom="updates"
                      />
                      {/* <!-- /.box-body --> */}
                    </div>
                  </div>
                </section>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
