import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useBodyClass = (classes) => {
  const location = useLocation();

  useEffect(() => {
    // Add each class in the array to the body
    classes
      .split(" ")
      .forEach((className) => document.body.classList.add(className));

    // Clean up the classes when the component unmounts or location changes
    return () => {
      classes
        .split(" ")
        .forEach((className) => document.body.classList.remove(className));
    };
  }, [classes, location.pathname]);
};

export default useBodyClass;
