import React, { useState, useEffect } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Table } from "../../small-components/table";
import { myTeamTable, teamJson } from "../../../utils/constant";
import moment from "moment";
import service from "../../../service";
import toastr from "toastr";
import { Audio } from "react-loader-spinner";
import { getUserInfo } from "../../../utils/common";
export const MyTeam = () => {
  const getInfo = JSON.parse(getUserInfo());
  const [myData, setMyData] = useState([]);
  const [startData, setStartDate] = useState(
    null
    // moment().startOf("day").format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = useState(
    null
    // moment().add(1, "days").startOf("day").format("YYYY/MM/DD")
  );
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [side, setSide] = useState(null);
  useEffect(() => {
    getData(offset, limit, startData, endDate, side);
  }, []);
  const getData = async (offset, limit, startData, endDate, side) => {
    setIsLoading(true);
    const res = await service.requestApi.myTeam(
      offset,
      limit,
      startData,
      endDate,
      side
    );
    if ("data" in res) {
      if (res?.data?.length > 0) {
        const finalArray = [];
        res?.data?.map((rs, idx) => {
          const Obj = {
            id: idx + 1,
            username: rs?.username ?? "er",
            name: rs?.name ?? "er",
            // reffid: rs?.reffid ?? "er",
            sponser: getInfo?.userName ?? "er",
            dateofjoining: moment(rs?.dateofjoining).format("DD/MM/YYYY"),
            upline: rs?.upline ?? "er",
            onside: rs?.onside ?? "er",
            joinAmount: rs?.joinAmount ?? "er",
            status: rs?.status ?? "er",
          };
          finalArray.push(Obj);
        });
        setMyData(finalArray);
      } else {
        setMyData(res?.data);
      }
      setTotalCount(res?.totalRecord);
      setIsLoading(false);
    } else {
      toastr.error("No data found or Error Occuried", "Error");
      setIsLoading(false);
    }
  };
  const handlePageChange = (PageNumber) => {
    getData(PageNumber, limit, startData, endDate, side);
    setOffset(PageNumber);
    setActivePage(PageNumber);
  };
  const filderSide = (e) => {
    const value = e?.target?.value;
    setSide(value);
    setActivePage(1);
    getData(1, limit, startData, endDate, value);
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Income Wallet"} child={"My Team"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Downline Member Detailt</h4>
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <select
                        className="custom-select form-control required"
                        onChange={(e) => filderSide(e)}
                      >
                        {teamJson.map((py, idx) => (
                          <option value={py?.value ?? null} key={idx}>
                            {py?.name ?? "er"}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div
                  className="content clearfix"
                  style={{ minHeight: "100px" }}
                >
                  <section className="content">
                    <div className="row">
                      <div class="box">
                        <div class="box-header with-border">
                          {/* <h4 class="box-title">Support Response Table</h4> */}
                        </div>
                        {/* <!-- /.box-header --> */}
                        {isLoading ? (
                          <Audio
                            height="80"
                            width="80"
                            radius="9"
                            color="green"
                            ariaLabel="loading"
                            wrapperStyle
                            wrapperClass
                          />
                        ) : (
                          <Table
                            tHeader={myTeamTable}
                            tData={myData}
                            calledFrom="my_team"
                            handlePageChange={handlePageChange}
                            totalCount={totalCount}
                            limit={limit}
                            pageRangeDisplayed={2}
                            activePage={activePage}
                            item={myData?.length ?? 0}
                          />
                        )}
                        {/* <!-- /.box-body --> */}
                      </div>
                    </div>
                  </section>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
