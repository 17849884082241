import React from "react";

export const ContactUSHome = () => {
  return (
    <>
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>Contact Us</h2>
                <ul className="bread-list">
                  <li>
                    <a href="index.html">Home</a>
                  </li>
                  <li>
                    <i className="icofont-simple-right"></i>
                  </li>
                  <li className="active">Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-content-wrapper contact-section">
        <div className="container">
          <div className="sec-title centered mb-20">
            <h1>Send Us A Message</h1>
          </div>
          <div className="default-form-area">
            <form id="contact-form">
              <div className="row clearfix">
                <div className="col-md-6 column">
                  <div className="form-group">
                    <input
                      type="text"
                      name="form_name"
                      className="form-control"
                      value=""
                      placeholder="Name"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 column">
                  <div className="form-group">
                    <input
                      type="email"
                      name="form_email"
                      className="form-control required email"
                      value=""
                      placeholder="Email"
                      required
                    />
                  </div>
                </div>
                <div className="col-md-6 column">
                  <div className="form-group">
                    <input
                      type="text"
                      name="form_phone"
                      className="form-control"
                      value=""
                      placeholder="Phone"
                    />
                  </div>
                </div>
                <div className="col-md-6 column">
                  <div className="form-group">
                    <input
                      type="text"
                      name="form_subject"
                      className="form-control"
                      value=""
                      placeholder="Subject"
                    />
                  </div>
                </div>
                <div className="col-md-12 column">
                  <div className="form-group">
                    <textarea
                      name="form_message"
                      className="form-control textarea required"
                      placeholder="Message..."
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="contact-section-btn">
                <div className="form-group style-two">
                  <button
                    className="btnContact"
                    type="submit"
                    data-loading-text="Please wait..."
                  >
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>

          <div className="contact-info">
            <div className="sec-title centered mb-20">
              <h1>Get In Touch</h1>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="contact-info-block">
                  <h5>Address</h5>
                  <div className="text">
                    SATYALOK COLONEY, PHASE -6 NEAR RANVEER GARDEN HALDWANI,
                    UTTARAKHAND, NAINITAL, PIN -263139
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="contact-info-block">
                  <h5>Email</h5>
                  <div className="text">info@gmfxglobal.group</div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12">
                <div className="contact-info-block">
                  <h5>Phone</h5>
                  <div className="text">+91 9258044511</div>
                  <div className="text">+91 7535039811</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
