import React, { useEffect, useRef, useState } from "react";
// import BGImage from "../../../images/gallery/creative/img-1.jpg";
import service from "../../../service";
import { generateColor } from "../../../utils/common";
import toastr from "toastr";
import { getUserInfo } from "../../../utils/common";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";
export const DashBoard = () => {
  const marqueeRef = useRef(null);
  const [news, setNews] = useState({});
  const [dashboardData, setDashboard] = useState({});
  const [shareText, setShareText] = useState(null);
  const userData = JSON.parse(getUserInfo());
  const [copy, setCopy] = useState(false);
  useEffect(() => {
    getNews();
    getDashboard();
    const text = `http://gmfxglobal.com/register.aspx?Sponsor=${
      userData?.userName ?? "er"
    }`;
    setShareText(text);
    Swal.fire({
      title: "Important Notice",
      text: "Dear User! Your Income Capping Limit Completed, Now Re-TopUp Time, with Amount Rs 20000.00",
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: "Re-TopUp Now",
      cancelButtonText: "Closed this Time",
      denyButtonText: "Logout",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        console.log("isConfirmed");
      } else if (result.isDenied) {
        console.log("isDenied");
      } else {
        console.log("isCancel");
      }
    });
  }, []);

  const getNews = async () => {
    const res = await service.requestApi.getNews();
    if ("news" in res && "tittle" in res) {
      setNews(res);
    } else {
      toastr.error("Error Occuried", "Error");
    }
  };
  const getDashboard = async () => {
    const res = await service.requestApi.getDashboard();
    if (
      "totalTeam" in res &&
      "totalInvestment" in res &&
      "leftTeamBusiness" in res
    ) {
      setDashboard(res);
    } else {
      toastr.error("No data found or Error Occuried", "Error");
    }
  };
  const handleMouseOver = () => {
    marqueeRef.current.stop();
  };
  const handleMouseOut = () => {
    marqueeRef.current.start();
  };
  const onCopy = () => {
    setCopy(true);
    setTimeout(() => {
      setCopy(false);
    }, 1500);
  };
  return (
    // <!-- Content Wrapper. Contains page content -->
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) -->	   */}
        <div className="content-header">
          <div className="d-flex align-items-center">
            <div className="mr-auto">
              <h3 className="page-title br-0">Dashboard</h3>
            </div>
            {/* <div   className="right-title w-170">
					<span   className="subheader_daterange font-weight-600" id="dashboard_daterangepicker">
						<span   className="subheader_daterange-label">
							<span   className="subheader_daterange-title"></span>
							<span   className="subheader_daterange-date text-primary"></span>
						</span>
						<a href="#"   className="btn btn-rounded btn-sm btn-primary">
							<i   className="fa fa-angle-down"></i>
						</a>
					</span>
				</div> */}
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* <h3>News :-</h3> */}
              <marquee
                className="marquee-blink"
                ref={marqueeRef}
                style={{ fontSize: "10px" }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
              >
                <span>
                  <span
                    style={{
                      fontSize: "16px",
                      color: generateColor(),
                      fontWeight: "bold",
                      textDecoration: "underline",
                    }}
                  >
                    {`${news?.tittle ?? ""} :- `}
                  </span>
                </span>
                <span>
                  <span style={{ fontSize: "20px", color: generateColor() }}>
                    {`${news?.news}.`}
                  </span>
                </span>
              </marquee>
            </div>
          </div>
          <div className="box mb-0">
            <div className="box-header">Referral Link</div>
            <div className="box-body">
              <div className="row">
                <div className="col-md-6">
                  <h4 className="box-title" style={{ lineHeight: "2" }}>
                    {shareText}
                  </h4>
                </div>
                <div className="col-md-2">
                  <CopyToClipboard text={shareText} onCopy={() => onCopy()}>
                    <button type="button" className="btn btn-success">
                      {copy ? "Copied" : `Copy and Share `}
                      {!copy && <i class="fa fa-share" aria-hidden="true"></i>}
                    </button>
                  </CopyToClipboard>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- Main content --> */}
        <section className="content">
          <div className="row">
            <div className="col-xl-3 col-md-6 col-12">
              <div className="box pull-up">
                <div className="box-body">
                  <div className="text-center">
                    <p className="mb-0">Total Direct</p>
                    <h2 className="font-size-30 font-weight-700 my-0 text-primary">
                      {dashboardData?.totaldirect ?? "0"}
                    </h2>
                    {/* <p className="text-fade">($17.4k Last Year)</p> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-around">
                    <div className="text-success">
                      {/* <i className="fa fa-arrow-up"></i> 142.11% */}
                    </div>
                    <div>
                      <div id="chart-1" className="py-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="box pull-up">
                <div className="box-body">
                  <div className="text-center">
                    <p className="mb-0">Total Team</p>
                    <h2 className="font-size-30 font-weight-700 my-0 text-primary">
                      {dashboardData?.totalTeam ?? "0"}
                    </h2>
                    {/* <p className="text-fade">($16.4k Last Year)</p> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-around">
                    <div className="text-danger">
                      {/* <i className="fa fa-arrow-down"></i> 12.11% */}
                    </div>
                    <div>
                      <div id="chart-2" className="py-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="box pull-up">
                <div className="box-body">
                  <div className="text-center">
                    <p className="mb-0">Total Income</p>
                    <h2 className="font-size-30 font-weight-700 my-0 text-primary">
                      {dashboardData?.totalIncome ?? "0"}
                    </h2>
                    {/* <p className="text-fade">($10.4k Last Year)</p> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-around">
                    <div className="text-success">
                      {/* <i className="fa fa-arrow-up"></i> 42.11% */}
                    </div>
                    <div>
                      <div id="chart-3" className="py-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-md-6 col-12">
              <div className="box pull-up">
                <div className="box-body">
                  <div className="text-center">
                    <p className="mb-0">Total Investment</p>
                    <h2 className="font-size-30 font-weight-700 my-0 text-primary">
                      {dashboardData?.totalInvestment ?? "0"}
                    </h2>
                    {/* <p className="text-fade">($11.4k Last Year)</p> */}
                  </div>
                  <div className="d-flex align-items-center justify-content-around">
                    <div className="text-danger">
                      {/* <i className="fa fa-arrow-down"></i> 27.11% */}
                    </div>
                    <div>
                      <div id="chart-4" className="py-10"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="col-12">
              <div class="row no-gutters py-2">
                <div class="col-12 col-lg-4">
                  <div class="box-body br-1 border-light">
                    <div class="flexbox mb-1">
                      <span>
                        <i class="ion-person font-size-26"></i>
                        <br />
                        Left Team Business
                      </span>
                      <span class="text-primary font-size-30">
                        {dashboardData?.leftTeamBusiness ?? "0"}
                      </span>
                    </div>
                    <div class="progress progress-xxs mt-10 mb-0">
                      <div
                        class="progress-bar"
                        role="progressbar"
                        style={{ width: "35%", height: "4px" }}
                        aria-valuenow={dashboardData?.leftTeamBusiness ?? 0}
                        aria-valuemin="0"
                        aria-valuemax="1000000"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-4 hidden-down">
                  <div class="box-body br-1 border-light">
                    <div class="flexbox mb-1">
                      <span>
                        <i class="ion-document font-size-26"></i>
                        <br />
                        Right Team Business
                      </span>
                      <span class="text-info font-size-30">
                        {dashboardData?.rightTeamBusiness ?? "0"}
                      </span>
                    </div>
                    <div class="progress progress-xxs mt-10 mb-0">
                      <div
                        class="progress-bar bg-info"
                        role="progressbar"
                        style={{ width: "55%", height: "4px" }}
                        aria-valuenow={dashboardData?.rightTeamBusiness ?? 0}
                        aria-valuemin="0"
                        aria-valuemax="1000000"
                      ></div>
                    </div>
                  </div>
                </div>

                <div class="col-12 col-lg-4 d-none d-lg-block">
                  <div class="box-body br-1 border-light">
                    <div class="flexbox mb-1">
                      <span>
                        <i class="ion-information font-size-26"></i>
                        <br />
                        Total Business
                      </span>
                      <span class="text-warning font-size-30">
                        {dashboardData?.totalBusiness ?? "0"}
                      </span>
                    </div>
                    <div class="progress progress-xxs mt-10 mb-0">
                      <div
                        class="progress-bar bg-warning"
                        role="progressbar"
                        style={{ width: "65%", height: "4px;" }}
                        aria-valuenow={dashboardData?.totalBusiness ?? 0}
                        aria-valuemin="0"
                        aria-valuemax="1000000"
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <div className="col-12">
          <div className="box">
            <div className="box-header">
              <h4 className="box-title">Income Summary</h4>
            </div>
            <div className="box-body">
              <div class="row">
                <div class="col-xl-4 col-12">
                  <div class="box box-body bg-info">
                    <h6 class="text-uppercase">Trading Bonus</h6>
                    <div class="flexbox mt-2">
                      <span class="ion ion-ios-heart text-danger font-size-40"></span>
                      <span class=" font-size-30">
                        {dashboardData?.tradingBonus ?? "0"}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <!-- /.col --> */}

                <div class="col-xl-4 col-12">
                  <div class="box box-body bg-success">
                    <h6 class="text-uppercase">Club Income</h6>
                    <div class="flexbox mt-2">
                      <span class=" font-size-30">
                        {dashboardData?.clubIncome ?? "er"}
                      </span>
                      <span class="ion ion-ios-heart font-size-30"></span>
                    </div>
                  </div>
                </div>
                {/* <!-- /.col --> */}
                <div class="col-xl-4 col-12">
                  <div class="box box-body bg-info">
                    <h6 class="text-uppercase">Level Income</h6>
                    <div class="flexbox mt-2">
                      <span class="ion ion-ios-chatboxes  font-size-30"></span>
                      <span class=" font-size-30">
                        {dashboardData?.levelIncome ?? "0"}
                      </span>
                    </div>
                  </div>
                </div>
                {/* <!-- /.col --> */}
              </div>
              <div className="box">
                <div className="row">
                  <div class="col-xl-3 col-12">
                    <div class="flexbox flex-justified text-center bg-white">
                      <div class="no-shrink py-30">
                        <span class="ion ion-arrow-graph-up-right font-size-50 text-success"></span>
                      </div>

                      <div class="py-30 bg-light">
                        <div class="font-size-20">
                          {dashboardData?.tradingProfitIncome ?? "0"}
                        </div>
                        <span>Trading Profit Income</span>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.col --> */}
                  <div class="col-xl-3 col-12">
                    <div class="flexbox flex-justified text-center bg-success">
                      <div class="no-shrink py-30">
                        <span class="ion ion-arrow-graph-up-right font-size-50"></span>
                      </div>

                      <div class="py-30 bg-light text-dark">
                        <div class="font-size-20">
                          {dashboardData?.royaltyIncome ?? "0"}
                        </div>
                        <span>Royalty Income</span>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.col --> */}
                  <div class="col-xl-3 col-12">
                    <div class="flexbox flex-justified text-center bg-white">
                      <div class="no-shrink py-30">
                        <span
                          class="ion ion-arrow-graph-up-right font-size-50 text-success"
                          style={{ color: "#3b5998" }}
                        ></span>
                      </div>

                      <div class="py-30 bg-light">
                        <div class="font-size-20">
                          {dashboardData?.totalPayout ?? "0"}
                        </div>
                        <span>Total Payout</span>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /.col --> */}
                  <div class="col-xl-3 col-12">
                    <div class="flexbox flex-justified text-center bg-success">
                      <div class="no-shrink py-30">
                        <span class="ion ion-arrow-graph-up-right font-size-30"></span>
                      </div>

                      <div class="py-30 bg-light text-dark">
                        <div class="font-size-20">
                          {dashboardData?.rewardIncome ?? "0"}
                        </div>
                        <span>Reward Income</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- /.content --> */}
      </div>
    </div>
  );
};
