import React, { useEffect, useState } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Link } from "react-router-dom";
import { Input } from "../../small-components/inputFiled";
import { previewFile } from "../../../utils/common";
import { InputFile } from "../../small-components/inputFile";
import service from "../../../service";
import { LoaderSpinner } from "../../../utils/common";
import toastr from "toastr";
export const Bank = () => {
  const [bankDetails, setBankDetails] = useState({ accImg: "null" });
  const [isLoading, setIsLoading] = useState(false);
  const [bankImg, setBankImg] = useState(null);
  useEffect(() => {
    getDetails();
  }, []);
  const getDetails = async () => {
    const res = await service.requestApi.getBankDetail();
    if ("accno" in res && "acctype" in res && "ifsc" in res) {
      setBankDetails(res);
    } else {
      toastr.error("No data found or Error Occuried", "Error");
    }
  };
  const updateBankData = (e) => {
    const value = e.target.value;
    const name = e.target.name;

    const cloneData = { ...bankDetails, [name]: value };
    setBankDetails(cloneData);
  };
  const onHandleSubmit = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("file", bankImg);
    const res = await service.requestApi.bankDetails(bankDetails, formData);
    if (res === 1) {
      toastr.success("Bank Details updated successfully", "Success");
      getDetails();
      setIsLoading(false);
    } else {
      toastr.error("Error while updating data, try again", "Error");
      setIsLoading(false);
    }
  };
  const onUploadFile = (e) => {
    const file = e?.target?.files[0];
    console.log("e ===>>", e, file);
    setBankImg(file);
    previewFile("img_preview");
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"KYC / BANK"} child={"Bank"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Bank</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Your Bank Status"}
                          name={"status"}
                          required={false}
                          type={"text"}
                          value={bankDetails?.status ?? ""}
                          onHandleChange={updateBankData}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Admin Remark For You"}
                          name={"remark"}
                          required={false}
                          type={"text"}
                          value={bankDetails?.remark ?? ""}
                          onHandleChange={updateBankData}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Account Number"}
                          name={"accno"}
                          required={false}
                          type={"number"}
                          value={bankDetails?.accno ?? ""}
                          onHandleChange={updateBankData}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"IFSC Code "}
                          name={"ifsc"}
                          required={false}
                          type={"text"}
                          value={bankDetails?.ifsc ?? ""}
                          onHandleChange={updateBankData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Bank Name "}
                          name={"bankname"}
                          required={false}
                          type={"text"}
                          value={bankDetails?.bankname ?? ""}
                          onHandleChange={updateBankData}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Branch Name"}
                          name={"branchname"}
                          required={false}
                          type={"text"}
                          value={bankDetails?.branchname ?? ""}
                          onHandleChange={updateBankData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Account Holder Name"}
                          name={"holdername"}
                          required={false}
                          type={"text"}
                          value={bankDetails?.holdername ?? ""}
                          onHandleChange={updateBankData}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label for="wlastName2"> Account Type :</label>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="demo-radio-button">
                            <input
                              name="acctype"
                              type="radio"
                              id="radio_31"
                              className="with-gap radio-col-info"
                              value="saving"
                              checked={bankDetails.acctype === "saving"}
                              onChange={updateBankData}
                            />
                            <label htmlFor="radio_31">Saving</label>
                            <input
                              name="acctype"
                              type="radio"
                              id="radio_32"
                              className="with-gap radio-col-info"
                              value="current"
                              checked={bankDetails.acctype === "current"}
                              onChange={updateBankData}
                            />
                            <label htmlFor="radio_32">Current</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="row">
                          <div className="col-md-6">
                            <InputFile
                              previewFile={onUploadFile}
                              inputID="file-input"
                              labelFor="file-input"
                              label="Upload file"
                            />
                          </div>
                          <div className="col-md-6">
                            <div
                              className="custom_img_preview"
                              id="img_preview"
                            ></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link
                        to="/dashboard"
                        role="menuitem"
                        style={{ color: "#999999", backgroundColor: "#ffffff" }}
                      >
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li aria-hidden="true" style={{ display: "none;" }}>
                      <a
                        role="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => onHandleSubmit()}
                      >
                        {isLoading ? <LoaderSpinner /> : "Save"}
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
