export const supportResponseTable = [
  { header: "#" },
  { header: "Date" },
  { header: "Subject" },
  { header: "Message" },
  { header: "Response" },
];
export const actionHistoryTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Amount" },
  { header: "Status" },
  { header: "Payment Mode" },
  { header: "UTR. No " },
  { header: "Receipt" },
  { header: "View" },
  { header: "Admin Remark" },
  { header: "Date of Request" },
  { header: "Date of Action" },
];
export const mainWalletSummaryTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Particular" },
  { header: "Credit" },
  // { header: "Debit" },
];
export const awardAndRewardTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Reward Level" },
  { header: "Reward" },
  { header: "Paid Status" },
  { header: "Re-TopUp Status" },
];
export const clubReportTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Club Name" },
  { header: "Salary" },
  { header: "TotalMonths" },
  { header: "Status" },
];
export const historyTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Amount" },
  { header: "Pack-type" },
  { header: "DOR" },
];
export const tradingBonusTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "ROI" },
  { header: "Total" },
  { header: "DOI" },
  { header: "GST" },
  { header: "TDS" },
];
export const levelIncomeTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Particular" },
  { header: "Credit" },
];
export const tradingProfiltIncomeTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Particular" },
  { header: "Credit" },
  // { header: "Debit" },
];
export const rewardReportTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Reward Level" },
  { header: "Reward" },
  { header: "Paid Status" },
  { header: "Re-TopUp Status" },
];
export const payoutHistoryTable = [
  { header: "#" },
  { header: "UserID" },
  { header: "Amount" },
  { header: "AdminCharge" },
  { header: "Wallet Transfer" },
  { header: "Wallet" },
  { header: "DOR" },
  { header: "Status" },
];
export const myTeamTable = [
  { header: "#" },
  { header: "UserName" },
  { header: "Name" },
  { header: "Sponser" },
  { header: "DOR" },
  { header: "Working Sponsor" },
  { header: "Side" },
  { header: "Investment" },
  { header: "AccountStatus" },
];
export const myDirectTeam = [
  { header: "#" },
  { header: "UserName" },
  { header: "Name" },
  { header: "Mobile" },
  { header: "DOR" },
  { header: "Sponser" },
  { header: "Purchase Package" },
  { header: "Account Status" },
];
export const rankStatusTable = [
  { header: "#" },
  { header: "LEVEL" },
  { header: "DOA" },
  { header: "Target LEFT" },
  { header: "Target RIGHT" },
  // { header: "Working Sponsor" },
  { header: "Available LEFT" },
  { header: "Available RIGHT" },
  { header: "Require LEFT" },
  { header: "Require RIGHT" },
  { header: "Status" },
];
export const sliderTable = [
  { header: "#" },
  { header: "Title" },
  { header: "Text" },
  { header: "Summary" },
  { header: "Mobile" },
  { header: "Image" },
  { header: "Action" },
];
export const serviceTable = [
  { header: "#" },
  { header: "Title" },
  { header: "Text" },
  { header: "Image" },
  { header: "Icon" },
  { header: "Action" },
];
export const updatesTable = [
  { header: "#" },
  { header: "Running Days" },
  { header: "Total Accounts" },
  { header: "Visitors" },
  { header: "Last Days" },
  { header: "Action" },
];
export const aboutUSTable = [
  { header: "#" },
  { header: "Title" },
  { header: "Text" },
  { header: "Vision" },
  { header: "Mission" },
  { header: "Opportunities" },
  { header: "Platform" },
  { header: "Image" },
  { header: "Action" },
];
export const testinomialTable = [
  { header: "#" },
  { header: "Username" },
  { header: "Text" },
  { header: "Salutation" },
  { header: "Image" },
  { header: "Action" },
];
export const contactUSTable = [
  { header: "#" },
  { header: "Name" },
  { header: "Mobile" },
  { header: "Email" },
  { header: "Message" },
];
export const rewardHeader = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Particular" },
  { header: "Credit" },
  // { header: "Debit" },
];
export const royaltyHeader = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Particular" },
  { header: "Credit" },
  // { header: "Debit" },
];
export const clubIncomeHeader = [
  { header: "#" },
  { header: "UserName" },
  { header: "Date" },
  { header: "Particular" },
  { header: "Credit" },
  // { header: "Debit" },
];
export const teamBusinessHeader = [
  { header: "#" },
  { header: "UserName" },
  { header: "Business" },
  { header: "Name" },
];
export const dummyData = [
  {
    id: "#1",
    date: "21-05-2024",
    subject: "Text",
    message: "testing",
    response: "Send",
  },
  {
    id: "#2",
    date: "22-05-2024",
    subject: "Text",
    message: "testing",
    response: "Send",
  },
  {
    id: "#3",
    date: "23-05-2024",
    subject: "Text",
    message: "testing",
    response: "Send",
  },
  {
    id: "#4",
    date: "24-05-2024",
    subject: "Text",
    message: "testing",
    response: "Send",
  },
  {
    id: "#5",
    date: "25-05-2024",
    subject: "Text",
    message: "testing",
    response: "Send",
  },
];
export const paymentJson = [
  { name: "Bank", value: "bank" },
  { name: "Cash", value: "cash" },
  { name: "UPI", value: "upi" },
];
export const teamJson = [
  { name: "All Member", value: "null" },
  { name: "Left Member", value: "left" },
  { name: "Right Member", value: "right" },
];

export const uploadMsg = "Image size could not be more then 10MB";
export const investMsgOne = "Investment/Re-Investment Amount should be minimum";
export const investMsgTwo = " 10000 INR..";
export const investMsgThree = "Thank You";
