import React from "react";
import { Link } from "react-router-dom";

export const AboutUsHome = () => {
  return (
    <>
      <div className="breadcrumbs overlay">
        <div className="container">
          <div className="bread-inner">
            <div className="row">
              <div className="col-12">
                <h2>About Us</h2>
                <ul className="bread-list">
                  <li>
                    <a href="/#">Home</a>
                  </li>
                  <li>
                    <i className="icofont-simple-right"></i>
                  </li>
                  <li className="active">About Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="inner-content-wrapper about-sec">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="about-content-block text-center">
                <h3>
                  The vision of the company is to establish a benchmark in the
                  field of investment so that every investor & promoter can get
                  financial success and make their dreams come true, also to
                  create an entrepreneurship model with future investment
                  and its returns.
                </h3>
                <div className="text mb-40 mt-10 text-center">
                  <p>
                    We are gives you an opportunity to become the owner of own
                    business and leader of your team. Every time you speak to
                    someone about your business you are involved in marketing.
                    Any conversation about your firm is an opportunity to
                    promote your business and increase sales.
                    <br />A marketing strategy will help you focus. It will
                    identify the different ways you can talk to your customers,
                    and concentrate on the ones that will create most sales.
                  </p>
                  {/* <ul className="list-style-one">
                    <li>Arts Programs</li>
                    <li>Online skilled Courses</li>
                    <li>Online Course Description</li>
                    <li>Successful Growth In our Institution</li>
                  </ul> */}
                </div>
                <div className="link-btn mb-30 text-center">
                  <Link
                    to="/contactus"
                    role="menuitem"
                    tabindex="-1"
                    className="bttn"
                  >
                    Contact Us
                  </Link>
                  {/* <a href="contact.html" className="bttn">
                    Contact Us
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
