import React, { useState } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Input } from "../../small-components/inputFiled";
import service from "../../../service";
import { Link } from "react-router-dom";
import { cancelBtn } from "./master-helper/helper";
import toastr from "toastr";

export const Support = () => {
  const [subject, setSubject] = useState(null);
  const [msg, setMSG] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const postData = async () => {
    setIsLoading(true);
    const res = await service.requestApi.support(subject, msg);
    if (res === 1) {
      setIsLoading(false);
    } else {
      toastr.error("Error Occuried", "Error");
      setIsLoading(false);
    }
  };
  const onHandleChange = (e) => {
    const value = e?.target?.value ?? null;
    const name = e?.target?.name ?? "";
    if (name === "subject") {
      setSubject(value);
    } else {
      setMSG(value);
    }
  };
  const onSubmit = () => {
    if (!!subject && msg) {
      setIsLoading(true);
      postData();
    } else {
      toastr.error("Please enter subject and message", "Error");
    }
  };

  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Master"} child={"Support"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Support</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div
                  className="content clearfix"
                  style={{ minHeight: "100px" }}
                >
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          label={"Subject"}
                          name={"subject"}
                          required={false}
                          type={"text"}
                          onHandleChange={onHandleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="wlastName2">
                            {" "}
                            Message <span className="danger">*</span>{" "}
                          </label>
                          <textarea
                            name="msg"
                            id="msg"
                            rows="6"
                            className="form-control"
                            onChange={(e) => onHandleChange(e)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link to="/dashboard" role="menuitem" style={cancelBtn}>
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li aria-hidden="true" style={{ display: "none;" }}>
                      <a
                        href="javascript:void(0)"
                        role="menuitem"
                        onClick={() => onSubmit()}
                      >
                        Sent
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
