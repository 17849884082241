import React, { useState, useEffect } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Table } from "../../small-components/table";
import { mainWalletSummaryTable } from "../../../utils/constant";
import { getUserInfo } from "../../../utils/common";
import service from "../../../service";
import moment from "moment";
import toastr from "toastr";
import { Audio } from "react-loader-spinner";


export const MainWalletSummary = () => {
  const getInfo = JSON.parse(getUserInfo());
  const [myData, setMyData] = useState([]);
  const [startData, setStartDate] = useState(
    null
    // moment().startOf("day").format("YYYY/MM/DD")
  );
  const [endDate, setEndDate] = useState(
    null
    // moment().add(1, "days").startOf("day").format("YYYY/MM/DD")
  );
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getData(offset, limit, startData, endDate);
  }, []);
  const getData = async (offset, limit, startData, endDate) => {
    setIsLoading(true);
    const res = await service.requestApi.mainWalletSummary(
      offset,
      limit,
      startData,
      endDate
    );
    if ("data" in res) {
      if (res?.data?.length > 0) {
        const finalArray = [];
        res?.data?.map((rs, idx) => {
          const Obj = {
            id: idx + 1,
            username: rs?.username ?? getInfo?.userName ?? "er",
            date: moment(rs?.data).format("DD/MM/YYYY"),
            particular: rs?.remark ?? "er",
            credit: rs?.credit ?? "er",
            // debit: rs?.debit ?? "er",
          };
          finalArray.push(Obj);
        });

        setMyData(finalArray);
      } else {
        setMyData(res?.data);
      }
      setIsLoading(false);
      setTotalCount(res?.totalRecord);
    } else {
      toastr.error("No data found or Error Occuried");
      setIsLoading(false);
    }
  };
  const handlePageChange = (PageNumber) => {
    getData(PageNumber, limit, startData, endDate);
    setOffset(PageNumber);
    setActivePage(PageNumber);
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Investment"} child={"Main Wallet Summary"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Main Wallet Summary</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div class="box">
                      <div class="box-header with-border">
                        <h4 class="box-title"> Wallet Summary</h4>
                      </div>
                      {/* <!-- /.box-header --> */}
                      {isLoading ? (
                        <Audio
                          height="80"
                          width="80"
                          radius="9"
                          color="green"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      ) : (
                        <Table
                          tHeader={mainWalletSummaryTable}
                          tData={myData}
                          calledFrom="main_wallet_summary"
                          handlePageChange={handlePageChange}
                          totalCount={totalCount}
                          limit={limit}
                          pageRangeDisplayed={2}
                          activePage={activePage}
                          item={myData?.length ?? 0}
                        />
                      )}
                      {/* <!-- /.box-body --> */}
                    </div>
                  </section>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
