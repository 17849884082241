import React from "react";
import { HomeHeader } from "./home-header";
import { HomeFooter } from "./home-footer";
import "../../assets/css/slicknav.min.css";
import "../../assets/css/home.css";
import "../../assets/css/et-line-font.css";
import "../../assets/css/boxicons.min.css";

export const HomeLayout = (props) => {
  return (
    <div className="wrapper">
      <HomeHeader />
      {/* <SidebarControl/> */}
      {props.children}
      <HomeFooter />
    </div>
  );
};
