import React from "react";

export const FormHeader = ({ parent = "ER", child = "ER" }) => {
  return (
    <div className="content-header">
      <div className="d-flex align-items-center">
        <div className="mr-auto">
          <h3 className="page-title">{child}</h3>
          <div className="d-inline-block align-items-center">
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">
                    <i className="mdi mdi-home-outline"></i>
                  </a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  {parent}
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {child}
                </li>
              </ol>
            </nav>
          </div>
        </div>
        {/* <div className="right-title">
          <div className="dropdown">
            <button
              className="btn btn-outline dropdown-toggle no-caret"
              type="button"
              data-toggle="dropdown"
            >
              <i className="mdi mdi-dots-horizontal"></i>
            </button>
            <div className="dropdown-menu dropdown-menu-right">
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-share"></i>Activity
              </a>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-email"></i>Messages
              </a>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-help-circle-outline"></i>FAQ
              </a>
              <a className="dropdown-item" href="#">
                <i className="mdi mdi-settings"></i>Support
              </a>
              <div className="dropdown-divider"></div>
              <button type="button" className="btn btn-rounded btn-success">
                Submit
              </button>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};
