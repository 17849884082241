// Example reducer
const initialState = {
  toggle: false,
};

export const sidebarToggleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "is_toggle":
      return { ...state, isToggle: action.toggle };
    default:
      return state;
  }
};
