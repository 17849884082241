import React, { useState } from "react";
import GMXLogo from "../../assets/images/gmfx-logo.png";
import GMXPDF from "../../assets/plan.pdf";
import { Link } from "react-router-dom";
import img4 from "../../assets/images/sectionFooter.png";

export const HomeHeader = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const mobileBtn = () => {
    setIsMobileNavOpen(!isMobileNavOpen);
  };
  return (
    <>
      {/* <!-- Header Area --> */}
      <header className="header">
        {/* <!-- Topbar --> */}
        <div className="topbar">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-5 col-12">
                {/* <!-- Contact --> */}
                <ul className="top-link">
                  <li>
                    <Link to="/about">About</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  {/* <li>
                    <a href="faq.html">FAQ</a>
                  </li> */}
                </ul>
                {/* <!-- End Contact -->  */}
              </div>
              <div className="col-lg-6 col-md-7 col-12">
                {/* <!-- Top Contact --> */}
                <ul className="top-contact" style={{ width: "110%" }}>
                  <li>
                    <i className="fa fa-phone"></i>+91 75350 39811
                  </li>
                  <li>
                    <i className="fa fa-envelope"></i>
                    <a href="mailto:support@yourmail.com">
                      info@gmfxglobal.group
                    </a>
                  </li>
                  <li>
                    {" "}
                    <Link
                      to="/#"
                      className="btn btb-sm btn-rounded btn-success"
                      // style={{ marginRight: "10px" }}
                    >
                      Registration
                    </Link>{" "}
                  </li>
                  <li>
                    {" "}
                    <Link
                      to="/login"
                      className="btn btb-sm btn-rounded btn-primary"
                    >
                      Login
                    </Link>{" "}
                  </li>
                </ul>
                {/* <!-- End Top Contact -->  */}
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Topbar -->  */}
        {/* <!-- Header Inner --> */}
        <div className="header-inner">
          <div className="container">
            <div className="inner">
              <div className="row">
                <div className="col-lg-3 col-md-3 col-12">
                  {/* <!-- Start Logo --> */}
                  <div className="logo">
                    {" "}
                    <Link to="/">
                      <img
                        src={GMXLogo}
                        alt=""
                        style={{ marginTop: "0px", width: "200px" }}
                      />
                    </Link>{" "}
                  </div>
                  {/* <!-- End Logo -->  */}
                  {/* <!-- Mobile Nav --> */}
                  <div className="mobile-nav">
                    <div className="slicknav_menu">
                      <a
                        // href="javascript:(void);"
                        // aria-haspopup="true"
                        role="button"
                        onClick={() => mobileBtn()}
                        tabindex="0"
                        className={
                          isMobileNavOpen
                            ? "slicknav_btn slicknav_collapsed"
                            : "slicknav_btn slicknav_open"
                        }
                        style={{ outline: "none" }}
                      >
                        <span className="slicknav_menutxt"></span>
                        <span className="slicknav_icon slicknav_no-text">
                          <span className="slicknav_icon-bar"></span>
                          <span className="slicknav_icon-bar"></span>
                          <span className="slicknav_icon-bar"></span>
                        </span>
                      </a>
                      <ul
                        className="slicknav_nav slicknav_hidden"
                        aria-hidden="true"
                        role="menu"
                        style={
                          isMobileNavOpen
                            ? { display: "block" }
                            : { display: "none" }
                        }
                      >
                        <li className="active">
                          <Link to="/login" role="menuitem" tabindex="-1">
                            Login
                          </Link>
                        </li>
                        <li className="active">
                          <Link to="/" role="menuitem" tabindex="-1">
                            Home
                          </Link>
                        </li>
                        <li>
                          <Link to="/about" role="menuitem" tabindex="-1">
                            About Us
                          </Link>
                        </li>
                        <li>
                          <Link to="/contact" role="menuitem" tabindex="-1">
                            Contact Us
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <!-- End Mobile Nav -->  */}
                </div>
                <div className="col-lg-7 col-md-9 col-12">
                  {/* <!-- Main Menu --> */}
                  <div className="main-menu">
                    <nav className="navigation">
                      <ul className="nav menu">
                        <li className="active">
                          <Link to="/">Home</Link>
                        </li>
                        <li>
                          <Link to="/aboutus">About Us</Link>
                        </li>
                        <li>
                          <Link to="/contactus">Contact Us</Link>
                        </li>
                        {/* <li>
                          <a href="JavaScript:Void(0)">
                            Services <i className="icofont-rounded-down"></i>
                          </a>
                          <ul className="dropdown">
                            <li>
                              <a href="service.html">Service</a>
                            </li>
                            <li>
                              <a href="service-details.html">Service Details</a>
                            </li>
                          </ul>
                        </li> */}
                        {/* <li>
                          <a href="JavaScript:Void(0)">
                            Pages <i className="icofont-rounded-down"></i>
                          </a>
                          <ul className="dropdown">
                            <li>
                              {" "}
                              <a href="about.html">About Us</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="pricing.html">Pricing</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="testimonials.html">Testimonials</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="team.html">Team</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="terms-conditions.html">
                                Terms Conditions
                              </a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="privacy-policy.html">
                                Privacy Policy
                              </a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="log-in.html">Log In</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="sign-up.html">Sign Up</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="coming-soon.html">Coming Soon</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="faq.html">FAQ</a>{" "}
                            </li>
                            <li>
                              {" "}
                              <a href="404.html">404 Error</a>{" "}
                            </li>
                          </ul>
                        </li> */}
                        {/* <li>
                          <a href="JavaScript:Void(0)">
                            Blog <i className="icofont-rounded-down"></i>
                          </a>
                          <ul className="dropdown">
                            <li>
                              <a href="blog-grid.html">Blog Grid</a>
                            </li>
                            <li>
                              <a href="blog-single.html">Blog Details</a>
                            </li>
                          </ul>
                        </li> */}
                        <li>
                          <a
                            href={GMXPDF}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Business Plan
                          </a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {/* <!--/ End Main Menu -->  */}
                </div>
                <div className="col-lg-2 col-12 d-flex justify-content-end">
                  <div className="get-quote">
                    {" "}
                    <img
                      src={img4}
                      alt=""
                      style={{
                        width: "20vw",
                      }}
                    />{" "}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* <!--/ End Header Inner --> */}
    </>
  );
};
