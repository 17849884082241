import React from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Table } from "../../small-components/table";
import { contactUSTable } from "../../../utils/constant";

export const ContactUS = () => {
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Front Admin"} child={"Contact US"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Contact US</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div class="box">
                      {/* <div class="box-header with-border">
                        <h4 class="box-title">Contact US</h4>
                      </div> */}
                      {/* <!-- /.box-header --> */}
                      <Table
                        tHeader={contactUSTable}
                        tData={[]}
                        calledFrom="contact_us"
                      />
                      {/* <!-- /.box-body --> */}
                    </div>
                  </section>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
