import React, { useState, useEffect } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Table } from "../../small-components/table";
import { actionHistoryTable } from "../../../utils/constant";
import service from "../../../service";
import moment from "moment";
import toastr from "toastr";
import { Audio } from "react-loader-spinner";
import { getUserInfo } from "../../../utils/common";
export const ActionHistory = () => {
  const [myData, setMyData] = useState([]);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [isLoading, setIsLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  useEffect(() => {
    getData(offset, limit);
  }, []);
  const getData = async (offset, limit) => {
    setIsLoading(true);
    const res = await service.requestApi.actionHistory(offset, limit);
    if ("data" in res) {
      if (res?.data?.length > 0) {
        const finalArray = [];
        res?.data?.map((rs, idx) => {
          const Obj = {
            id: idx + 1,
            username: JSON.parse(getUserInfo()).userName ?? "er",
            amount: rs?.amount ?? "er",
            status: rs?.status ?? "er",
            paymentmode: rs?.paymentmode ?? "er",
            utrNo: "No Data",
            receipt: rs?.receipt ?? "",
            clientremark: rs?.clientremark ?? "er",
            remark: rs?.remark ?? "",
            dod: moment(rs?.dod).format("DD/MM/YYYY"),
            dor: moment(rs?.dor).format("DD/MM/YYYY"),
          };
          finalArray.push(Obj);
        });

        setMyData(finalArray);
      } else {
        setMyData(res?.data);
      }
      setTotalCount(res?.totalRecord);
      setIsLoading(false);
    } else {
      toastr.error("No data found or Error Occuried");
      setIsLoading(false);
    }
  };
  const handlePageChange = (PageNumber) => {
    getData(PageNumber, limit);
    setOffset(PageNumber);
    setActivePage(PageNumber);
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Investment"} child={"Action Hisotry"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Action History</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div class="box">
                      <div class="box-header with-border">
                        <h4 class="box-title">Wallet Recharge Action List</h4>
                      </div>
                      {/* <!-- /.box-header --> */}
                      {isLoading ? (
                        <Audio
                          height="80"
                          width="80"
                          radius="9"
                          color="green"
                          ariaLabel="loading"
                          wrapperStyle
                          wrapperClass
                        />
                      ) : (
                        <Table
                          tHeader={actionHistoryTable}
                          tData={myData}
                          calledFrom="action_request"
                          handlePageChange={handlePageChange}
                          totalCount={totalCount}
                          limit={limit}
                          pageRangeDisplayed={2}
                          activePage={activePage}
                          item={myData?.length ?? 0}
                        />
                      )}
                      {/* <!-- /.box-body --> */}
                    </div>
                  </section>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
