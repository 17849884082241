import React, { useEffect, useState } from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Link } from "react-router-dom";
import { Input } from "../../small-components/inputFiled";
import service from "../../../service";
import toastr from "toastr";
import moment from "moment";
import { LoaderSpinner } from "../../../utils/common";
export const Profile = () => {
  const [profileData, setProfileData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    getProfileData();
  }, []);
  const getProfileData = async () => {
    const res = await service.requestApi.getProfileData();
    if ("address" in res && "city" in res && "country" in res) {
      setProfileData(res);
    } else {
      toastr.error("No data found or Error Occuried", "Error");
    }
  };
  const updateProfileData = (e) => {
    const value = e?.target?.value ?? "";
    const name = e.target?.name ?? "";
    const cloneData = JSON.parse(JSON.stringify(profileData));
    cloneData[name] = value;
    setProfileData(cloneData);
  };
  const onHandleSubmit = async () => {
    setIsLoading(true);
    const res = await service.requestApi.profile(profileData);
    if (res === 1) {
      toastr.success("profile updated successfully", "Success");
      getProfileData();
      setIsLoading(false);
    } else {
      toastr.error("Error while updating data, try again", "Error");
      setIsLoading(false);
    }
  };
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Master"} child={"Profile"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Personal Detail</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Full Name"}
                          name={"name"}
                          required={true}
                          type={"text"}
                          value={profileData?.name ?? ""}
                          onHandleChange={updateProfileData}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Father / Husband Name"}
                          name={"fname"}
                          required={true}
                          type={"text"}
                          value={profileData?.fname ?? ""}
                          onHandleChange={updateProfileData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Email Address"}
                          name={"email"}
                          required={true}
                          type={"email"}
                          value={profileData?.email ?? ""}
                          onHandleChange={updateProfileData}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Phone Number"}
                          name={"mobile"}
                          required={true}
                          type={"tel"}
                          value={profileData?.mobile ?? ""}
                          onHandleChange={updateProfileData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Country"}
                          name={"country"}
                          required={true}
                          type={"tel"}
                          value={profileData?.country ?? ""}
                          onHandleChange={updateProfileData}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Date of Birth"}
                          name={"dob"}
                          required={false}
                          type={"date"}
                          value={moment(profileData?.dob ?? "").format(
                            "YYYY-MM-DD"
                          )}
                          onHandleChange={updateProfileData}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <label for="shortDescription3">Address :</label>
                          <textarea
                            name="address"
                            // id="address"
                            rows="6"
                            className="form-control"
                            value={profileData?.address ?? ""}
                            onChange={(e) => updateProfileData(e)}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Picode"}
                          name={"pin"}
                          required={true}
                          type={"number"}
                          value={profileData?.pin ?? ""}
                          onHandleChange={updateProfileData}
                        />
                      </div>
                    </div>
                  </section>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
          {/* nominee detail */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Nominee Detail</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <div className="content clearfix">
                <section
                  id="steps-uid-2-p-0"
                  role="tabpanel"
                  aria-labelledby="steps-uid-2-h-0"
                  className="body current"
                  aria-hidden="false"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        label={"Nominee Name"}
                        name={"nomineename"}
                        required={true}
                        type={"test"}
                        value={profileData?.nomineename ?? ""}
                        onHandleChange={updateProfileData}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={"Nominee Age"}
                        name={"nage"}
                        required={false}
                        type={"number"}
                        value={profileData?.nage ?? ""}
                        onHandleChange={updateProfileData}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        label={"Phone Number"}
                        name={"nomineemobile"}
                        required={true}
                        type={"tel"}
                        value={profileData?.nomineemobile ?? ""}
                        onHandleChange={updateProfileData}
                      />
                    </div>
                    <div className="col-md-6">
                      <Input
                        label={"Relationship with Nominee"}
                        name={"relation"}
                        required={true}
                        type={"text"}
                        value={profileData?.relation ?? ""}
                        onHandleChange={updateProfileData}
                      />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
          {/* additional data */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Admission Detail</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Member ID"}
                          name={"username"}
                          required={true}
                          type={"text"}
                          value={profileData?.username ?? ""}
                          onHandleChange={updateProfileData}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Sponse ID"}
                          name={"reffid"}
                          required={false}
                          type={"text"}
                          value={profileData?.reffid ?? ""}
                          onHandleChange={updateProfileData}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Joining Date"}
                          name={"dateofjoin"}
                          required={false}
                          type={"date"}
                          value={moment(profileData?.dateofjoin ?? "").format(
                            "YYYY-MM-DD"
                          )}
                          onHandleChange={updateProfileData}
                          disabled
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Account Status"}
                          name={"status"}
                          required={false}
                          type={"text"}
                          value={profileData?.status ?? ""}
                          onHandleChange={updateProfileData}
                          disabled
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link
                        to="/dashboard"
                        role="menuitem"
                        style={{ color: "#999999", backgroundColor: "#ffffff" }}
                      >
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li aria-hidden="true" style={{ display: "none;" }}>
                      <a
                        // href="javascript:(void);"
                        role="button"
                        style={{ cursor: "pointer" }}
                        onClick={() => onHandleSubmit()}
                      >
                        {isLoading ? <LoaderSpinner /> : "Save"}
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
