import React from "react";
import { FormHeader } from "../../small-components/formHeader";
import { Link } from "react-router-dom";
import { Input } from "../../small-components/inputFiled";
import {
  investMsgOne,
  investMsgThree,
  investMsgTwo,
  paymentJson,
  uploadMsg,
} from "../../../utils/constant";

export const InvestmentRequest = () => {
  return (
    <div className="content-wrapper">
      <div className="container-full">
        {/* <!-- Content Header (Page header) --> */}
        <FormHeader parent={"Investment"} child={"Investment Request"} />
        <section className="content">
          {/* <!-- Validation wizard --> */}
          <div className="box box-default">
            <div className="box-header with-border">
              <h4 className="box-title">Investment Request</h4>
              {/* <h6 className="box-subtitle">You can us the validation like what we did</h6> */}
            </div>
            {/* <!-- /.box-header --> */}
            <div className="box-body wizard-content">
              <form
                className="validation-wizard wizard-circle wizard clearfix"
                role="application"
                id="steps-uid-2"
                novalidate="novalidate"
              >
                <span style={{ color: "red" }}>
                  {investMsgOne}
                  <span style={{ color: "green" }}>{investMsgTwo}</span>
                  <span>{investMsgThree}</span>
                </span>
                <div className="content clearfix">
                  <section
                    id="steps-uid-2-p-0"
                    role="tabpanel"
                    aria-labelledby="steps-uid-2-h-0"
                    className="body current"
                    aria-hidden="false"
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          label={"Member ID"}
                          name={"memberid"}
                          required={false}
                          type={"text"}
                          value={"GDF1212"}
                          disabled
                          style={{ border: "none" }}
                        />
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"UTR NO."}
                          name={"utrno"}
                          required={false}
                          type={"text"}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label for="wlocation2">
                            {" "}
                            Select Payment Mode :{" "}
                            <span className="danger">*</span>{" "}
                          </label>
                          <select
                            className="custom-select form-control required"
                            id="wlocation2"
                            name="location"
                          >
                            <option value="">Select City</option>
                            {paymentJson.map((py, idx) => (
                              <option value={py?.value ?? "er"} key={idx}>
                                {py?.name ?? "er"}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Input
                          label={"Upload Reciept"}
                          name={"upload_reciept"}
                          required={false}
                          type={"text"}
                        />
                      </div>
                    </div>
                  </section>
                </div>
                <div className="actions clearfix">
                  <ul role="menu" aria-label="Pagination">
                    <li className="disabled" aria-disabled="true">
                      <Link
                        to="/dashboard"
                        role="menuitem"
                        style={{ color: "#999999", backgroundColor: "#ffffff" }}
                      >
                        Cancel
                      </Link>
                    </li>
                    {/* <li aria-hidden="false" aria-disabled="false"><a href="#next" role="menuitem">Next</a></li> */}
                    <li aria-hidden="true" style={{ display: "none;" }}>
                      <a href="#finish" role="menuitem">
                        Save
                      </a>
                    </li>
                  </ul>
                </div>
              </form>
            </div>
            {/* <!-- /.box-body --> */}
          </div>
        </section>
        {/* <!-- /.box-body --> */}
      </div>
    </div>
  );
};
